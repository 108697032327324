import React from "react";
import { ScanScreenProps } from "./types";
import {
  BlockRow,
  BlockColumn,
  ScanBlock,
  ScanTitle,
  ScanDescription,
  ScannerContainer,
  Wrapper,
  ScannerImage,
  ScanBorder,
  SuccessImage,
  ScanTitleInfo,
  ListItem,
  SuccessMsg,
  WarningMessage,
  SubListItem,
  ScannerImageTwo,
} from "./styles";

import { useState, useEffect } from "react";

const ScanScreen: React.FC<ScanScreenProps> = (props) => {
  const {
    scanTitle,
    scanDescription,
    imageName,
    children,
    isSuccesAlertShown,
    instructions,
    message,
    scanFullHeight,
    isWarningMessage,
    subInstructions,
  } = props;

  // get the window width
  const getWindowWidth = () => {
    const { innerWidth: width } = window;
    return width;
  };

  const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());

    // update the window width with every resizing
    useEffect(() => {
      function handleResize() {
        setWindowWidth(getWindowWidth());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowWidth;
  };

  const currentWindowWidth = useWindowWidth();

  return (
    <BlockRow>
      {currentWindowWidth <= 450 && (
        <BlockColumn>
          <ScannerContainer>
            <Wrapper fullHeight={scanFullHeight}>{children}</Wrapper>
          </ScannerContainer>
        </BlockColumn>
      )}

      <BlockColumn>
        <ScanBlock>
          {currentWindowWidth <= 450 && message && (
            <ScanBorder>
              {isSuccesAlertShown && (
                <SuccessMsg data-testid={`toast-message`}>{message}</SuccessMsg>
              )}
              {isWarningMessage && (
                <WarningMessage data-testid={`toast-message`}>
                  {message}
                </WarningMessage>
              )}
            </ScanBorder>
          )}

          <ScanTitleInfo>
            <ScannerImage src={`/assets/images/${imageName}.png`} />
            <ScanTitle data-testid={"informationTitle"}>{scanTitle}</ScanTitle>
            <ScanDescription>{scanDescription}</ScanDescription>

            <ul>
              {instructions &&
                instructions.map((instruction: string, index: number) => (
                  <ListItem
                    key={`instruction-${index}`}
                    data-testid={`instruction-${index}`}
                  >
                    {instruction}
                  </ListItem>
                ))}
            </ul>
            <ul>
              {subInstructions &&
                subInstructions.map((subInstruction: string, index: number) => (
                  <SubListItem
                    key={`subInstruction-${index}`}
                    data-testid={`subInstruction-${index}`}
                  >
                    {subInstruction}
                  </SubListItem>
                ))}
            </ul>
          </ScanTitleInfo>

          {currentWindowWidth > 450 && (
            <ScanBorder>
              {isSuccesAlertShown && (
                <SuccessMsg data-testid={`toast-message`}>{message}</SuccessMsg>
              )}
              {isWarningMessage && (
                <WarningMessage data-testid={`toast-message`}>
                  {message}
                </WarningMessage>
              )}
            </ScanBorder>
          )}
        </ScanBlock>
      </BlockColumn>

      {currentWindowWidth > 450 && (
        <BlockColumn>
          <ScannerContainer>
            <Wrapper fullHeight={scanFullHeight}>{children}</Wrapper>
          </ScannerContainer>
        </BlockColumn>
      )}
    </BlockRow>
  );
};

export default ScanScreen;
