import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/button";
import ScanScreen from "../../components/ScanScreen";
import {
  InputTextdiv,
  InputFieldTag,
  InputFieldCheckBox,
  Inputcheckboxdiv,
  SelectBox
} from "./styles";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import { useLocation, useHistory } from "react-router-dom";
import apiRequest from "../../apirequest";
import PopupModal from "../../components/PopupModal";
import UserDetailsSmallIcon from "../../app-assets/images/userdetails_small_icon.png";
import CreateUserSmallIcon from "../../app-assets/images/createuser_small_icon.png";

const CreateUser = () => {
  const breadCrumRoutes = [
    {
      name: "UserDetails",
      path: "/userdetails",
      icon: UserDetailsSmallIcon,
      testId: "UserDetails_Data",
    },
    {
      name: "CreateUser",
      path: "/createuser",
      icon: CreateUserSmallIcon,
      testId: "Createuser_Data",
    },
  ];

  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const location: any = useLocation();
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const [userListEditDetails, setuserListEditDetails] = useState<any>({
    operativename: "",
    lanid: "",
    uin: "",
    contactnumber: "",
    district: "",
    operativestatus: "",
  });
  const [isLoading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const history = useHistory();
  const [userName, SetUserName] = useState("");
  const [userLanId, SetUserLanId] = useState("");
  const [userUIN, SetUserUIN] = useState("");
  const [userDistrict, SetUserDistrict] = useState("");
  const [userContactNumber, SetUserContactNumber] = useState("");
  const [isSuccess, setSucessMsg] = useState(false);
  const [isWarning, setWarning] = useState(false);
  const [createSuccessMsg, setcreateSuccessMsg] = useState("");
  const [showBack, setShowBack] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [userType,setUserType]=useState<string>('ACTIVE');
  const [company,setCompany]=useState('');

  useEffect(() => {
    if (location?.state?.userEditData) {
      setReadOnly(true);
      const {
        operativename = "",
        lanid = "",
        uin = "",
        district = "",
        contactnumber = "",
        engineerstatus = "",
        companyname = ""
      } = location?.state?.userEditData || {};
      SetUserName(operativename);
      SetUserLanId(lanid);
      SetUserDistrict(district);
      SetUserUIN(uin);
      SetUserContactNumber(contactnumber);
      setUserType(engineerstatus);
      setCompany(companyname);
    }
  }, []);

  const handleNameChange = (event: any) => {
    const value = event.target.value;
    // console.log("Name", value);
    SetUserName(value);
  };
  const handleLanIdChange = (event: any) => {
    const value = event.target.value;
    // console.log("Lanid", value);

    SetUserLanId(value);
  };

  const handleUINChange = (event: any) => {
    const value = event.target.value;
    // console.log("UIN", value);
    SetUserUIN(value);
  };

  const handleDistrictChange = (event: any) => {
    const value = event.target.value;
    // console.log("District", value);
    SetUserDistrict(value);
  };

  const handleContactNumberChange = (event: any) => {
    const value = event.target.value;
    const regExp = /^[0-9]*$/;
    // console.log("ContactNumber", value);
    if (value.length <= 11 && (value == "" || value.match(regExp))) {
      SetUserContactNumber(value);
    }
    // console.log("ContactNumber", value);
  };

  const handleCancel = () => {
    setShowBack(true);
  };

  const handleCompanyName=(event:any)=>{
    setCompany(event.target.value)
  }

  const handleSave = () => {
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      operation: "userupsert",
      enggid: preferred_username,
      lanid: userLanId,
      operativename: userName,
      uin: userUIN,
      district: userDistrict,
      contactnumber: userContactNumber,
      engineerstatus: userType ? userType :'ACTIVE' ,
      companyname:company ? company:''
    };
    console.log("body Data", body)
    setLoading(true);
    apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const dataResponse = JSON.parse(response.data.response);
        console.log("Response***", dataResponse);
        setSucessMsg(true);
        setcreateSuccessMsg(dataResponse);
        setReadOnly(false);
        SetUserName("");
        SetUserUIN("");
        SetUserLanId("");
        SetUserDistrict("");
        SetUserContactNumber("");
        setUserType("ACTIVE");
        setCompany("");
        console.log("username", userName);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const hideBackButton = () => {
    setShowBack(false);
  };

  const handleCancelButton = () => {
    history.push("/userdetails");
  };

  const handleSelectData = (event: any) => {
    const value = event.target.value;
    // console.log("User Status", value);
    setUserType(value)
  };
  console.log(userType);

  return (
    <>
      <PopupModal
        show={showBack}
        handleClose={hideBackButton}
        primaryAction={handleCancelButton}
        yestestId={"cancelYesButton"}
        notestId={"cancelNoButton"}
      ></PopupModal>

      <BreadCrumb inputRoutes={breadCrumRoutes} />
      <Container wide>
        <Card>
          <ScanScreen
            imageName="createuser_icon"
            scanTitle={"Please fill all the mandatory fields  "}
            isSuccesAlertShown={isSuccess}
            message={createSuccessMsg}
            isWarningMessage={isWarning}
          >
            {isLoading && (
              <div style={{ position: "absolute", left: 0, right: 0 }}>
                <LoadingIndicators
                  size="lg"
                  position="center"
                  colour="gray"
                  type="circle"
                />
              </div>
            )}
            <div style={{ margin: "15px" }}>
              {/* <div style={{ margin: "auto", width: "80%" }}> */}
              <InputTextdiv style={{paddingTop:'0px'}}>
                <label>
                  Name
                  <span style={{ color: "Red", fontSize: "20px" }}>*</span>
                </label>
                <InputFieldTag
                  value={userName}
                  disabled={readOnly}
                  onChange={handleNameChange}
                  data-testid={"nameField"}
                />
              </InputTextdiv>
              <InputTextdiv>
                <label>
                  Lan- ID
                  <span style={{ color: "Red", fontSize: "20px" }}>*</span>
                </label>
                <InputFieldTag
                  value={userLanId}
                  disabled={readOnly}
                  onChange={handleLanIdChange}
                  style={{ textTransform: "uppercase" }}
                  data-testid={"lanField"}
                />
              </InputTextdiv>
              <InputTextdiv>
                <label>
                  UIN
                  <span style={{ color: "Red", fontSize: "20px" }}>*</span>
                </label>
                <InputFieldTag
                  value={userUIN}
                  disabled={readOnly}
                  onChange={handleUINChange}
                  data-testid={"uinField"}
                />
              </InputTextdiv>
              <InputTextdiv>
                <label>
                  District
                  <span style={{ color: "Red", fontSize: "20px" }}>*</span>
                </label>
                <InputFieldTag
                  value={userDistrict}
                  onChange={handleDistrictChange}
                  data-testid={"districtField"}
                />
              </InputTextdiv>

              <InputTextdiv>
                <label>
                  Phone Number
                  <span
                    style={{
                      color: "Red",
                      fontSize: "20px",
                      visibility: "hidden",
                    }}
                  >
                    *
                  </span>
                </label>
                <InputFieldTag
                  value={userContactNumber}
                  onChange={handleContactNumberChange}
                  data-testid={"contactNumberField"}
                />
              </InputTextdiv>
              <InputTextdiv>
              <label>Company Name</label>
              <InputFieldTag 
                value={company}
                onChange={handleCompanyName}
                data-testid={'companyNameField'}/>
              </InputTextdiv>
              <InputTextdiv>
                <label>
                  User Status
                  <span
                    style={{
                      color: "Red",
                      fontSize: "20px",
                      visibility: "hidden",
                    }}
                  >
                    *
                  </span>
                </label>

                <div
                  style={{
                    width: "75%",
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {/* <InputFieldCheckBox
                    type=""
                    onChange={handleCheckboxData}
                    checked={userStatus}
                  />
                  <span
                    style={{
                      padding: "10px",
                      // fontWeight: "bold",
                      fontSize: "17px",
                    }}
                  >
                    Active/Inactive
                  </span> */}
                  <SelectBox name="user_status" id="user_status" data-testid={'engg_status'} onChange={handleSelectData}>
                    <option value="active" selected={userType==='ACTIVE'? true:false}>Active</option>
                    <option value="inactive" selected={userType==='INACTIVE'? true:false}>Inactive</option>
                    <option value="dormant" selected={userType==='DORMANT'? true:false}>Dormant</option>
                  </SelectBox>
                </div>
              </InputTextdiv>
              <InputTextdiv>
                <div style={{ marginBottom: "20px" }}>
                  <Button
                    type="button"
                    label={"Save"}
                    size="lg"
                    testId={"SaveButton"}
                    onClick={handleSave}
                    bgColor="#6dde71"
                    textColor="#ffffff"
                    borderColor="#6dde71"
                    disabled={
                      !(
                        userName.length > 0 &&
                        userLanId.length > 0 &&
                        userUIN.length > 0 &&
                        userDistrict.length > 0
                      )
                    }
                  />{" "}
                  &nbsp;
                  <Button
                    type="button"
                    label={"Back"}
                    size="lg"
                    testId={"CancelButton"}
                    onClick={handleCancel}
                    bgColor="#6dde71"
                    textColor="#ffffff"
                    borderColor="#6dde71"
                  />
                </div>
              </InputTextdiv>
            </div>
          </ScanScreen>
        </Card>
      </Container>
    </>
  );
};

export default CreateUser;