import * as React from 'react';
import Wallace from '../Wallace';
import Btn from './style';
import { ButtonProps } from './types';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref): React.ReactElement => {
    // eslint-disable-next-line object-curly-newline
    const { type, label, href, size, onClick, disabled, bgColor, textColor, borderColor , testId } = props;

    const handleButtonClick = () => {
      onClick();
    };

    return (
      <Wallace>
        <Btn
          ref={ref}
          type={type}
          href={href}
          size={size}
          onClick={handleButtonClick}
          data-testid = {testId}
          disabled={disabled}
          bgColor={bgColor}
          textColor={textColor}
          borderColor={borderColor}
        >
          {label}
        </Btn>
      </Wallace>
    );
  },
);

export default Button;
