import React from 'react';
import Header from '../components/Header';
import StackHeader from '../components/StackHeader';
import Footer from '../components/Footer';

interface VehicleStockScreenProps {
    children: React.ReactNode
}

const VehicleStockScreen:React.FC<VehicleStockScreenProps> = (props) => {
    const { children } = props;
    return(
        <>
            <Header id="theme" title="Vehicle Stock Management" size="sm" />
            <StackHeader title={'Vehicle Stock Management'} />
            {children}
            <Footer/>
        </>
    )
}

export default VehicleStockScreen;