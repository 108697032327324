import React, { useState, useEffect } from "react";
import './App.css';
import Routes from "./Routes";
import VehicleStockScreen from './HOC/VehicleStockScreens';
import { Provider } from 'react-redux';
import configureStore from './store';



const App = () => {

  const store = configureStore();

  const unloadCallback = (event: any) => {
    event.preventDefault();
    return event.returnValue = 'Are you sure you want to close?';
  }

  useEffect(() => {
    window.addEventListener("beforeunload", unloadCallback);
    
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    }
  },[])

  // Hub.listen("auth", ({ payload: { event, data } }) => {
  //   console.log(event, data)
  //   if (event === "cognitoHostedUI") setSession()
  // });

  // const setSession = () => {
  //   Auth.currentSession()
  //     .then(session => {
  //       console.log('Session is valid')
  //       userHasAuthenticated(true)
  //       setUsername(session.getIdToken().payload.name)
  //     }).catch(e => {
  //       console.log('Session is not valid')
  //       console.log(e)
  //       Auth.federatedSignIn({ customProvider: config.Auth.provider })
  //       // Auth.federatedSignIn({provider:config.Auth.provider})
  //     })

  // }

  // useEffect(() => {
  //   setSession()
  // }, [])

  return (
    <div className="App">
      <Provider store={store}>
      <VehicleStockScreen>
        <Routes />
      </VehicleStockScreen>
      </Provider>
    </div>
  );
}

export default App;