import {refreshToken} from './HOC/refreshToken';


const config = {
    "env": "Local DEV",
    "refreshHandler": {
        "developer": refreshToken
    },
    "Auth": {
        "mandatorySignIn": true,
        "identityPoolId": "eu-west-1:c956c600-eb3d-4826-ba21-e2650d564bcf",      
        "identityPoolRegion": "eu-west-1",
        "region": "eu-west-1",
        "userPoolWebClientId": "4hddev9lhfr51k9ofebcc9jle7",
        "userPoolId": "eu-west-1_As3EEaGtP",
        "provider": "RED-AD", 
        "oauth": {
            "domain": "auth-staff.dev.customer-common.aws.edfcloud.io",
            "scope": ["phone","email","profile","openid","aws.cognito.signin.user.admin"],
            // "redirectSignIn": "http://localhost:3000",
            // "redirectSignOut": "http://localhost:3000",
            "redirectSignIn": "https://preprod.cus-fal-vsm.aws.edfcloud.io",
            "redirectSignOut": "https://preprod.cus-fal-vsm.aws.edfcloud.io",
            "responseType": "code"
        }
    },
    "API": {
        "endpoints": [
            // {
            //     "name": "legacy",
            //     "endpoint":"https://api.dev.customer-common.aws.edfcloud.io/legacy",
            //     "region": "eu-west-1"
            // },
            // {
            //     "name": "party-smart-preferences",
            //     "endpoint":"https://api.dev.customer-common.aws.edfcloud.io/party-smart-preferences",
            //     "region": "eu-west-1"
            // }
        ]
    },
    "restBaseUrl": 'https://preprod.cus-fal-vsm.aws.edfcloud.io/preprod',
    "engineeradgroup":"GBM-R-CUS-falvsmtest-engineer",
    "backofficeadgroup":"GBM-R-CUS-falvsmtest-backofficeuser",
    "adminadgroup":"GBM-R-CUS-falvsmtest-admin"

}


export default config; 