// import { useIndexedDB } from "react-indexed-db";
import apiRequest from '../../apirequest';
import { Constants } from "../../constants/constants";
import moment from "moment";
import { db } from '../../db';
// const vsm_status = {
//   "ST001": "Shipped",
//   "ST002": "Collected",
//   "ST003": "Scan Out",
//   "ST004": "Returned",
//   "ST005": "Unknown",
// }

// const vsm_operation_status = {
//   "collect_asset": "ST002",
//   "return_asset": "ST004",
//   "install_asset": "ST003"
// }

const getage = (assetcreated: string) => {
  let today = moment(new Date())
  console.log(today)
  let timecreated = new Date(assetcreated)
  console.log(timecreated)
  let datediff = today.diff(timecreated)
  console.log(datediff)
  let assetage = Math.ceil(datediff / (24 * 60 * 60 * 1000))
  console.log(assetage)
  return assetage
}

export const Refreshmyinventory = (enggid: string) => {
  // const { add, getAll, clear } = useIndexedDB("myinventory");
  const body = {
    operation: "fetchinventory",
    enggid: enggid,
    skucode: ""
  };
  apiRequest(Constants.toteEndPoint, JSON.stringify(body)).then((response: any) => {
    const inventoryDataList = JSON.parse(response.data.response);
    db.myinventory.clear().then(() => {
      // console.log('refreshing offline invemtory data')
      inventoryDataList.forEach((rec: any) => {
        let assetcreated = new Date()
        assetcreated.setDate(assetcreated.getDate() - rec.asset_age)
        let timecreated = moment(assetcreated).format('YYYY-MM-DD HH:mm:ss')
        let showage = false
        if (rec.asset_age != -1) {
          showage = true
        }
        let invdata = {
          totenumber: rec.totebarcode,
          assetserial: rec.assetserialnumber,
          statuscode: rec.statuscode,
          statusdesc: rec.status,
          reasoncode: rec.reason_code,
          reasondesc: rec.reasond,
          skucode: rec.skucode,
          skudesc: rec.skudesc,
          timecreated: moment(timecreated).format('YYYY-MM-DD HH:mm:ss'),
          timemodified: new Date(),
          showage: showage
        }
        let totenumber = rec.totebarcode
        let assetserial = rec.assetserialnumber
        let statuscode = rec.statuscode
        let statusdesc = rec.status
        let reasoncode = rec.reason_code
        let reasondesc = rec.reasond
        let skucode = rec.skucode
        let skudesc = rec.skudesc
        let timemodified = new Date()
        let dxdata = { totenumber, assetserial, statuscode, statusdesc, reasoncode, reasondesc, skucode, skudesc, timecreated, timemodified, showage }
        db.myinventory.add(dxdata)
      })

    }).catch((err) => {
      console.log(err)
    })
  })

}

export const Fetchfflineinventory = (skuCodeValue: string) => {
  // const { add, getAll, clear } = useIndexedDB("myinventory");
  console.log('Fetching offline assets')
  // const records = useLiveQuery(() => db.myinventory.toArray())
  const records: any[] = []

  let inventoryrecords = new Promise<any[]>(async (resolve, reject) => {
    let invrecords: any[] = [];
    let reccount = 0;
    await db.myinventory.each(invasset => records.push(invasset)).then(() => {
      console.log(records)
    })
    console.log(records)
    records?.forEach(rec => {
      let assetage = getage(rec.timecreated)
      console.log(rec.timecreated)
      console.log(assetage)
      if (rec.showage == false) {
        assetage = -1
      }
      let invrec = {
        asset_age: assetage,
        assetserialnumber: rec.assetserial,
        reason_code: rec.reasoncode,
        reasond: rec.reasondesc,
        scantimestamp: "",
        skucode: rec.skucode,
        skudesc: rec.skudesc,
        status: rec.statusdesc,
        statuscode: rec.statuscode,
        totebarcode: rec.totenumber
      }
      if (skuCodeValue != "") {

        if (rec.skucode != null && rec.skucode != undefined && skuCodeValue.toUpperCase() == rec.skucode.toUpperCase()) {
          invrecords.push(invrec)
        }
      } else {
        invrecords.push(invrec)
      }

      reccount = reccount + 1
      if (reccount == records.length) {
        console.log('inventory data loaded')
        resolve(invrecords);
      }
    })

  })
  return inventoryrecords
}

export const Searchofflineasset = (assetserial: string) => {
  // const { add, getAll, clear } = useIndexedDB("myinventory");
  const records: any[] = []

  let inventoryrecords = new Promise<any>(async (resolve, reject) => {
    let invrecords: any[] = [];
    let reccount = 0;
    await db.myinventory.each(invasset => records.push(invasset)).then(() => {
      console.log(records)
    })
    console.log(records)
    if (records) {
      console.log(records);
      records?.forEach(rec => {
        // let today = moment.now();
        let today = new Date();
        let timecreated = new Date(rec.timecreated);
        // let datediff = today - timecreated.getMilliseconds();
        let datediff: number = today.getTime() - timecreated.getTime();
        let assetage = Math.ceil(datediff / (24 * 60 * 60 * 1000));
        // console.log(rec.assetserial);
        // console.log(rec.timecreated);
        // console.log(assetage);

        if (rec.showage == false) {
          assetage = -1
        }
        let invrec = {
          asset_age: assetage,
          reasonid: rec.reasoncode,
          reasond: rec.reasondesc,
          asset_status: rec.statusdesc,
        }
        if (assetserial != "") {
          if (assetserial.toUpperCase() == rec.assetserial.toUpperCase()) {
            invrecords.push(invrec)
          }
        } else {
          invrecords.push(invrec)
        }

        reccount = reccount + 1
        if (reccount == records.length) {
          if (invrecords.length > 0) {
            let assetdata = {
              data: {
                response: JSON.stringify(invrecords)
              }
            }
            console.log(assetdata)
            resolve(assetdata);
          } else {
            let assetdata = {
              data: {
                response: JSON.stringify('No Record Found')
              }
            }
            console.log(assetdata)
            resolve(assetdata);
          }
          console.log('inventory data loaded')

        }
      })
    } else {
      let assetdata = {
        data: {
          response: JSON.stringify('No Record Found')
        }
      }
      console.log(assetdata)
      resolve(assetdata);
    }

  })
  return inventoryrecords
}

interface assetupdatebody {
  assetcode: string,
  operation: string,
  enggid: string,
  scan_time: Date,
  reasoncode: string
}
export const Updateofflineinventory = (body: assetupdatebody) => {
  // const { getByIndex, update, add, deleteRecord } = useIndexedDB("myinventory");
  const assetdataarr: any[] = []
  console.log(assetdataarr)
  let updateresult = new Promise(async (resolve, reject) => {
    // getByIndex('assetserial', body.assetcode.toUpperCase()).then((assetdata: any) => {
    await db.myinventory.where('assetserial').equals(body.assetcode.toUpperCase()).each(((res) => { assetdataarr.push(res) })).then(() => {
      console.log(assetdataarr)
    })

    if (assetdataarr.length > 0) {
      let assetdata = assetdataarr[0]
      // let assetid = assetdata.id
      let updatedassetdata = assetdata
      let statuscode = ""
      let statusdesc = ""
      let deleteasset = false
      if (body.operation == 'collect_asset') {
        statuscode = 'ST002'
        statusdesc = 'Collected'
      }
      else if (body.operation == 'install_asset') {
        statuscode = 'ST003'
        statusdesc = 'Scan Out'
      }
      else if (body.operation == 'return_asset') {
        if (body.reasoncode == 'R007') {
          statuscode = 'ST002'
          statusdesc = 'Collected'
        } else {
          statuscode = 'ST004'
          statusdesc = 'Returned'
          deleteasset = true
        }
      } else {

      }
      if (deleteasset) {
        updatedassetdata.statuscode = statuscode
        updatedassetdata.statusdesc = statusdesc
        updatedassetdata.reasoncode = body.reasoncode
        updatedassetdata.reasondesc = body.reasoncode === 'R003' ? 'Recalled' : ''
        db.myinventory.where('assetserial').equals(body.assetcode.toUpperCase()).delete().then(() => {
          let resbody = {
            data: {
              asset_age: getage(updatedassetdata.timecreated),
              asset_state: updatedassetdata.statusdesc,
              message: "Asset " + updatedassetdata.assetserial + " is " + updatedassetdata.statusdesc + " sucessfully ",
              skucode: updatedassetdata.skucode,
              state: "success",
              statusCode: 200
            }
          }
          resolve(resbody)
        })
        // deleteRecord(assetid).then(() => {

        // })
      } else {
        updatedassetdata.statuscode = statuscode
        updatedassetdata.statusdesc = statusdesc
        updatedassetdata.reasoncode = body.reasoncode
        updatedassetdata.reasondesc = body.reasoncode === 'R003' ? 'Recalled' : ''
        db.myinventory.where('assetserial').equals(body.assetcode.toUpperCase()).modify(updatedassetdata).then(() => {
          let resbody = {
            data: {
              asset_age: getage(updatedassetdata.timecreated),
              asset_state: updatedassetdata.statusdesc,
              message: "Asset " + updatedassetdata.assetserial + " is " + updatedassetdata.statusdesc + " sucessfully ",
              skucode: updatedassetdata.skucode,
              state: "success",
              statusCode: 200
            }
          }
          resolve(resbody)
        })
        // update(updatedassetdata).then((res) => {

        // })
      }

    } else {
      // let invdata = {
      //   totenumber: "",
      //   assetserial: body.assetcode.toUpperCase(),
      //   statuscode: 'ST002',
      //   statusdesc: 'Collected',
      //   reasoncode: '',
      //   reasondesc: '',
      //   skucode: null,
      //   skudesc: null,
      //   timecreated: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   timemodified: moment().format('YYYY-MM-DD HH:mm:ss'),
      //   showage: false
      // }
      let totenumber = ""
      let assetserial = body.assetcode.toUpperCase()
      let statuscode = 'ST002'
      let statusdesc = 'Collected'
      let reasoncode = ''
      let reasondesc = ''
      let skucode = null
      let skudesc = null
      let timemodified = new Date()
      let timecreated = moment().format('YYYY-MM-DD HH:mm:ss')
      let showage = false
      let dxdata = { totenumber, assetserial, statuscode, statusdesc, reasoncode, reasondesc, skucode, skudesc, timecreated, timemodified, showage }

      db.myinventory.add(dxdata).then(() => {
        let resbody = {
          data: {
            asset_age: -1,
            asset_state: statusdesc,
            message: "Asset " + assetserial + " is " + statusdesc + " sucessfully ",
            skucode: null,
            state: "success",
            statusCode: 200
          }
        }
        resolve(resbody)
      })
      // add(invdata).then((result) => {

      // })
    }

  })

  return updateresult;
}

