import styled, { keyframes } from 'styled-components';
import { IndicatorOptions } from './LoadingIndicators';

const sizes = {
  lg: '92',
  md: '44',
  sm: '14',
};

const colours = {
  gray: '#9D9D9D',
  blue: '#1057C8',
  green: '#4F9E30',
  red: '#C82C00',
};

const positions = {
  left: 'float: left',
  right: 'float: left',
  center: 'margin: auto',
};

const borderSizes = {
  lg: '6',
  md: '4',
  sm: '2',
};

const circleSpinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Circle = styled.div<IndicatorOptions>`
  position: relative;
  ${({ position }) => positions[position]};
  margin-top: 10px;
  margin-bottom: 10px;
  border: ${({ size }) => borderSizes[size]}px solid #eaeaea;
  border-radius: 50%;
  border-top: ${({ size }) => borderSizes[size]}px solid
    ${({ colour }) => colours[colour]};
  width: ${({ size }) => sizes[size]}px;
  height: ${({ size }) => sizes[size]}px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  animation-name: ${circleSpinAnimation};
`;

export default Circle;
