import { createGlobalStyle } from 'styled-components';

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
const GlobalStyle = createGlobalStyle`

  * {
    font-family: 'Noto Sans', Arial, sans-serif;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

`;

export default GlobalStyle;
