import React, { useState, useEffect } from "react";

import Grid from "../Grid";
import HeaderContainer, { NavHeader, NavLastLogin, NavEmail } from "./styles";
import { useSelector } from "react-redux";

type HeaderSizes = "xs" | "sm" | "md" | "lg";

interface HeaderProps {
  id: string;
  title?: string;
  size: HeaderSizes;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const today = new Date();

const monthValue = (today.getMonth() + 1).toString();
const dayValue = today.getDate().toString();
const hourValue = today.getHours().toString();
const minuteValue = today.getMinutes().toString();

const ampm = today.getHours() >= 12 ? "PM" : "AM";

const date =
  today.getFullYear() +
  "-" +
  (monthValue.length !== 1 ? monthValue : "0" + monthValue) +
  "-" +
  (dayValue.length !== 1 ? dayValue : "0" + dayValue);

const time =
  (hourValue.length !== 1 ? hourValue : "0" + hourValue) +
  ":" +
  (minuteValue.length !== 1 ? minuteValue : "0" + minuteValue) +
  " " +
  ampm;

const dateTime = date + " " + time;

const Header: React.FC<HeaderProps> = ({
  id,
  title,
  size,
  children,
  ...props
}) => {
  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const userName = userSession?.idToken?.payload?.name || "";
  return (
    <HeaderContainer {...props} data-testid={id}>
      <Grid>
        <Grid column sm={12} md={12}>
          {/* {children} */}
          <NavHeader>
            <NavEmail data-testid={"LoginUsername"}>Hi, {userName}</NavEmail>
            <NavLastLogin data-testid={"LoginUserLastLoginTime"}>
              {" "}
              Last Login : {dateTime}
            </NavLastLogin>
          </NavHeader>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
};

export default Header;
