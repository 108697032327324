import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/button";
import CameraScanner from "../../components/CameraScanner";
import CameraWindow from "../../components/CameraWindow";
import ScanScreen from "../../components/ScanScreen";
// import { useIndexedDB } from "react-indexed-db";
import { useHistory } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import axios from "axios";
import { Constants } from "../../constants/constants";
import { useSelector } from "react-redux";
import LoadingIndicators from "../../components/LoadingIndicators";
import apiRequest from "../../apirequest";
import ScanToteSmallIcon from "../../app-assets/images/scan_tote_small_icon.png";
import scanToteIcon from '../../app-assets/images/scantoteicon.png';


const ScanTote = () => {
  const breadCrumRoutes = [
    {
      name: "Scan Tote",
      path: "/scantote",
      icon: ScanToteSmallIcon,
      testId: "ScanTote_Data",
    },
  ];

  const instructions = [
    "Place cursor into (text box) . ",
    "Scan Tote using A2 option on Toughpad, or press “scan” button using mobile version.",
    "You can manually type without scanning.",
    "All Tote bar codes should start with RIE and can be found on the sticker attached to the Tote .",
    "Please check the tote is assigned to YOU and is displaying your name for collection.",
    "If VSM offline, Tote will not scan - TIP: Take picture of barcode to scan when back online.",
  ];

  const history = useHistory();
  const handleHomePage = () => {
    history.push("/home");
  };

  const [toteFound, setToteFound] = useState(true);
  const [cameraWindow, setCameraWindow] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [totenumber, setToteNumber] = useState("");
  const [toteMessage, setToteMessage] = useState("");
  const [allToteNumbers, setAllToteNumbers] = useState([]);
  const [dataWarning, setDataWarning] = useState(false);
  const isOnline = useSelector((state: any) => state.login.isOnline);
  const [isSuccess, setSucessTote] = useState(false);
  const [isWarning, setWarning] = useState(false);
  const [enableCollect, setEnableCollect] = useState(false);
  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  // console.log("preferred_username***",preferred_username);

  useEffect(() => { }, []);

  const handleScanAsset = () => {
    history.push("/scanasset");
  };

  const handleReset = () => {
    setToteNumber("");
    setToteFound(true);
    setToteMessage("");
    setSucessTote(false);
    setWarning(false);
    setEnableCollect(false);
  };

  const handleCollect = () => {
    // setSucessTote(true);
    // setTimeout(() => {
    //   setSucessTote(false);
    // }, 3000);
    if (isOnline == false) {
      setWarning(true);
      setToteMessage("Tote can not be collected in offline mode.");
    } else {
      //do - api call
      collectTote();
    }
  };

  const handleToteNumber = (event: any) => {
    const value = event.target.value;
    const regExp = /^[a-zA-Z0-9\s]*$/;
    if (value.length <= 88 && (value == "" || value.match(regExp))) {
      setToteFound(true);
      setEnableCollect(false);
      setWarning(false);
      setSucessTote(false);
      setToteMessage("");
      setToteNumber(value);
    }
  };

  const verifyTote = () => {
    if (isOnline == false) {
      setWarning(true);
      setToteMessage("Tote can not be collected in offline mode.");
    } else {
      const regExp = new RegExp("^" + "RIE", "i");
      // if (!regExp.test(totenumber)) {
      //   setWarning(true);
      //   setToteMessage("Invalid Tote. It should start with RIE.");
      //   return
      // }
      const config = {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        totecode: totenumber,
        operation: "fetchtote",
        enggid: preferred_username,
      };
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          setLoading(false);
          console.log("message", response);
          if (response.data) {
            const { data } = response;
            // console.log("error", data.body)
            let toteFound = true;
            let toteMessage = "";
            if (data.state == "tote found") {
              toteMessage = data.message;
              toteFound = true;
              setEnableCollect(true);
              setSucessTote(true);
              setWarning(false);
            } else {
              toteFound = false;
              setEnableCollect(false);
              toteMessage = data.message
                ? data.message
                : "Tote is not identified,please retry or scan the asset";
              setWarning(true);
            }

            setToteMessage(toteMessage);
            setToteFound(toteFound);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  };

  const collectTote = () => {
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      totecode: totenumber,
      scan_time: new Date(),
      operation: "collect",
      enggid: preferred_username,
    };
    setLoading(true);
    apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        const { data } = response;
        setLoading(false);
        // let toteMessage = `${data?.response}, Please scan next tote`;
        let toteMessage = `${data?.response}`;
        setEnableCollect(false);
        setToteMessage(toteMessage);
        setToteFound(toteFound);
        setSucessTote(true);
        setToteNumber("");
        setWarning(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const handleHomeButton = () => {
    if (totenumber) {
      setShow(true);
      return true;
    }
    return false;
  };

  const onPressHome = () => {
    if (!handleHomeButton()) {
      history.push("/home");
      // history.goBack();
    }
  };

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };
  return (
    <>
      <PopupModal
        show={show}
        handleClose={hideModal}
        primaryAction={handleHomePage}
        yestestId={"yesButton"}
        notestId={"noButton"}
      ></PopupModal>
      {!cameraWindow && (
        <BreadCrumb
          inputRoutes={breadCrumRoutes}
          callBackDataCheck={handleHomeButton}
        />
      )}
      <Container wide>
        <Card>
          {cameraWindow && <>{!isLoading && <CameraWindow />}</>}
          {!cameraWindow && (
            <ScanScreen
              imageName="scantoteicon"
              scanTitle={"Scan Tote Bar Code - "}
              scanDescription={
                "To add all searlised Assets inside the tote,into your vehicle Stock Inventory"
              }
              isSuccesAlertShown={isSuccess}
              instructions={instructions}
              message={toteMessage}
              isWarningMessage={isWarning}
            >
              <>
                {isLoading && (
                  <div style={{ position: "absolute", left: 0, right: 0 }}>
                    <LoadingIndicators
                      size="lg"
                      position="center"
                      colour="gray"
                      type="circle"
                    />
                  </div>
                )}
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      width: "75%",
                      margin: "auto",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <textarea
                        rows={2}
                        value={totenumber}
                        onChange={handleToteNumber}
                        style={{
                          resize: "none",
                          overflow: "hidden",
                          width: "100%",
                          padding: "50px 10px",
                          cursor: "pointer",
                          fontSize: "25px",
                          textAlign: "center",
                          // marginTop:'50px'
                        }}
                        placeholder={"Enter Tote Number.."}
                        data-testid={"toteField"}
                      />
                      <CameraScanner
                        handleCamWindow={setCameraWindow}
                        handleScan={setToteNumber}
                        handleCamToggle={setLoading}
                      />
                    </div>
                    &nbsp;
                    <Button
                      type="button"
                      disabled={!(totenumber && totenumber.length > 1)}
                      label={"Search"}
                      size="lg"
                      testId={"SearchButton"}
                      onClick={verifyTote}
                      bgColor="#0e7d11"
                    />
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    {toteFound ? (
                      <Button
                        type="button"
                        disabled={!enableCollect}
                        label={"Collect"}
                        size="lg"
                        testId={"CollectButton"}
                        onClick={handleCollect}
                        bgColor="#0e7d11"
                      />
                    ) : (
                      <>
                        <Button
                          type="button"
                          label={"Reset"}
                          size="lg"
                          testId={"ResetButton"}
                          onClick={handleReset}
                          bgColor="#0e7d11"
                        />{" "}
                        &nbsp;
                        <Button
                          type="button"
                          label={"Scan Asset"}
                          size="lg"
                          testId={"ScanButton"}
                          onClick={handleScanAsset}
                          bgColor="#0e7d11"
                        />
                      </>
                    )}
                    &nbsp;
                    <Button
                      type="button"
                      label="Home"
                      size="lg"
                      testId={"HomeButton"}
                      onClick={onPressHome}
                      bgColor="#0e7d11"
                    />
                  </div>
                </div>
              </>
            </ScanScreen>
          )}
        </Card>

        <div>
          {allToteNumbers.map((item: any, index: number) => {
            if (isOnline == false) {
              return <div key={index}>Totenumber: {item.totenumber}</div>;
            }
          })}
        </div>
      </Container>
    </>
  );
};

export default ScanTote;
