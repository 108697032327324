import styled from "styled-components";
import { ContainerProps } from "./types";

const ContainerWrapper = styled.div<ContainerProps>`
  position: relative;
  // border: 2px solid red;
  width: 100%;
  max-width: 960px;
  padding: 20px;

  ${({ wide }) =>
    wide &&
    `
        max-width: unset;
		// width: calc(100% - 40px);
        // min-width: 1000px;`}
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 450px) {
    padding: 0;
  }
`;

export { ContainerWrapper };
