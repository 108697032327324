import React from "react";
import {
  Model,
  Container,
  PopupHeader,
  HBar,
  ButtonBar,
  Slot,
  PopupText,
  PopupIcon,
  PopupContent
} from "./styles";
import Button from "../../components/button";
import { PopupModalProps } from "./types";
import warningIcon from '../../app-assets/images/warning_icon.png';


const PopupModal: React.FC<PopupModalProps> = (props) => {
    const { handleClose, openPos, show , primaryAction ,yestestId , notestId ,testMsg1,testMsg2} = props;

    // const sendYes = () => handleClose && handleClose(true);
    const sendNo = () => handleClose && handleClose(false);

	const handleRedirectPage = () => {
		primaryAction && primaryAction();
    }
    

  return (
    <Model show={show}>
      <Container openPos={openPos}>
        <PopupHeader>
          <PopupIcon src={warningIcon}></PopupIcon>
          <PopupText>Warning !</PopupText>
        </PopupHeader>
        <HBar />
        <PopupContent>
          <Slot>{testMsg1 || "Do you want to leave this site?"} </Slot>
          <Slot>{testMsg2 || "Have you saved the changes required?"} </Slot>
          <ButtonBar>
            <Button
              type="button"
              label="Yes"
              size="lg"
              testId={yestestId}
              onClick={handleRedirectPage}
              bgColor="#ffffff"
              textColor = "#ff4500"
            />
            &nbsp;
            <Button
              type="button"
              label="No"
              size="lg"
              testId={notestId}
              onClick={sendNo}
              bgColor="#0e7d11"
            />
          </ButtonBar>
        </PopupContent>
      </Container>
    </Model>
  );
};

export default PopupModal;
