import styled from "styled-components";

const CameraButton = styled.div`
   {
    font-style: normal;
    font-weight: bold;

    height: 50px;
    line-height: 30px;
    min-width: 120px;
    background-color: #0061bb;
    // border: 2px solid red;

    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    span {
      margin: 10px;
      font-size: 20px;
      color: white;
    }

    img {
      height: 35px;
      width: 35px;
    }
  img{
    height: 30px;
    width: 30px;
  }

  @media (min-width: 450px) {
    display: none;
  }
`;

export { CameraButton };
