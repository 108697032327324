import React from 'react';
import Card from '../../components/Card';
import Container from '../../components/Container';
import BreadCrumb from '../../components/BreadCrumb';
import {
    Model
  } from "./styles";

type unAuthUserProps = {
    message: string;
}

const UnauthorisedUser = (props: unAuthUserProps) => { 
    const { message } = props;
    return (
        <>
            <BreadCrumb inputRoutes={[]} />
			<Container>
				<Card>
                    <Model>{message}</Model>
                </Card>
            </Container>
        </>
    )
}

export default UnauthorisedUser;