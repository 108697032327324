// const REST_BASE_URL = 'https://dgop54l6oa.execute-api.eu-west-1.amazonaws.com/preprod';
// const REST_BASE_URL = 'https://preprod.cus-fal-vsm.aws.edfcloud.io/preprod';
// const REST_BASE_URL = 'https://dev.cus-fal-vsm.aws.edfcloud.io/dev';

import config from '../config';

const { restBaseUrl } = config;

const Constants = {
    toteEndPoint: `${restBaseUrl}/toteasset`,
    assetEndpoint: `${restBaseUrl}/asset`,
    onlineEndPoint:`${restBaseUrl}/checkonline`,
}

export { Constants };