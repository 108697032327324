import styled from "styled-components";
import { PopupModalProps } from "./types";
const Model = styled.div<PopupModalProps>`
  z-index: auto;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div<PopupModalProps>`
  // position: auto;
  background: antiquewhite;
  width: 30%;
  // height: auto;
  // top: 35%;
  // left: 33%;
  // border-radius: 10px;
  color: rgba(0, 0, 139, 0.7);
  margin: 15% auto;

  @media (max-width: 450px){
    width: 80%;
  }
`;

const PopupHeader = styled.div`
  background: #ff4500;
  padding: 10px;
  min-height: 70px;

  @media (max-width: 450px){
    padding: 5px;
    min-height: 50px;
  }
`;

const HBar = styled.div`
  width: 100%;
  height: 1px;
  border: solid 1px rgba(80, 80, 150, 0.4);
  background-color: rgba(80, 80, 150, 0.4);
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  justify-content: center;
  margin-top: 12px;
`;

const Slot = styled.div`
  font-size: 25px;
  color: #000000;

  @media (max-width: 450px){
    font-size: 15px;
  }
`;
const PopupIcon = styled.img`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  float: left;
  height: 55px;
  width: 55px;

  @media (max-width: 450px){
    height: 35px;
    width: 35px;
  }
`;

const PopupText = styled.div`
  color: #ffffff;
  font-size: 45px;

  @media (max-width: 450px){
    font-size: 25px;
  }
`;

const PopupContent = styled.div`
  padding: 20px;
  background-color:#daeaf7;
`;

export {
  Model,
  Container,
  PopupHeader,
  HBar,
  ButtonBar,
  Slot,
  PopupText,
  PopupIcon,
  PopupContent,
};
