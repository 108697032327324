import { SAVE_LOGIN_INFO, SET_NETWORK_STATE, SET_ENGINEER_ACCESS } from '../actions/loginActions';

const initialState: any = {
    loginState: {},
    isOnline: false,
    engineerAcces: false
}

export const loginReducer = (state: any = initialState, action: any) => {
    const { type } = action;
    // console.log("___________________")
    // console.log("action ***", action)
    // console.log("___________________")
    switch(type) {
        case SAVE_LOGIN_INFO:
            return {
                ...state,
                loginState: action.payload
            };
        case SET_NETWORK_STATE:
            return {
                ...state,
                isOnline: action.payload
            }
        case SET_ENGINEER_ACCESS:
            return {
                ...state,
                engineerAcces: action.payload
            }
        default:
            return state;
    }
};