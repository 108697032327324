import React, { useEffect, useState } from "react"
import { Route, Switch, BrowserRouter } from "react-router-dom"
import Home from './pages/home/home';
import ScanTote from './pages/scantote/scantote';
import ScanAsset from './pages/scanasset/scanasset';
import MyInventory from './pages/myinventory/myinventory';
import Search from './pages/search/search';
import axios from "axios"
import { Auth } from "aws-amplify"
import config from './config';
import { saveLoginData, engineerAccessAction, setNetworkState } from './store/actions/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import BackOfficeHome from './pages/backofficehome/backofficehome';
import UserDetails from './pages/userdetails/userdetails';
import Reports from './pages/reports/reports';
import CreateUser from './pages/createuser/createuser';
import apiRequest from "./apirequest";
import { Constants } from './constants/constants';
import UnauthorisedUser from './pages/unauthorised/unauthoriseduser';

enum Roles {
  ENGINEER = 'ENGINEER',
  ADMIN = 'ADMIN',
  BACKOFFICE = 'BACKOFFICE'
}

const isAuthenticated = () => (
  Auth.currentSession()
    .then((session: any) => {
      window.localStorage.removeItem('failRedirectFlag');
      return { authenticated: true, session: session };
    })
    .catch(err => {
      const failRedirectFlag = window.localStorage.getItem('failRedirectFlag');

      if (failRedirectFlag === null){
        window.localStorage.setItem('failRedirectFlag', "redirected");
        Auth.federatedSignIn({ customProvider: config.Auth.provider });
      }
      else if (failRedirectFlag === "redirected"){
        window.localStorage.removeItem('failRedirectFlag');
      }

      return { authenticated: false };
    })
);

const userType = (userSession: any) => {
  try {
    const userRoles = userSession?.idToken?.payload['cognito:groups'] || [];
    const engineerRole = userRoles.includes(config.engineeradgroup);
    const adminRole = userRoles.includes(config.adminadgroup);
    const backOfficerRole = userRoles.includes(config.backofficeadgroup);
    let role = '404'
    if (adminRole)
      role = Roles.ADMIN;
    else if (backOfficerRole)
      role = Roles.BACKOFFICE;
    else if (engineerRole)
      role = Roles.ENGINEER;
    return role;
  } catch (e) {
    return '404';
  }

}

const PrivateAuthControl = ({ component: ChildComponent, appProps, roles = [], ...rest }: any) => {
  const auth = useSelector((state: any) => state.login.loginState);
  const hasEngineerAccess = useSelector((state: any) => state.login?.engineerAcces) || false;
  const authenticated = auth.authenticated;
  let hasAccess = false;
  const loggedInUser = userType(auth.session);
  hasAccess = roles.includes(loggedInUser);
 
  return (
    <Route
      {...rest}
      render={(props: any) => 
        {
          if (authenticated === true){
            if (hasAccess){
              if (loggedInUser === Roles.ENGINEER && !hasEngineerAccess){
                // if the user role is "Engineer" then see if he/she is added from back office
                return <UnauthorisedUser message={"Either you don't have access in VSM or your access has been revoked, Please check with Back Office"} />
              }
              return <ChildComponent {...props} {...appProps} />
            }
            else{
              return <UnauthorisedUser message={"You are not authorised to access this page"} />
            }
          }
          else{
            return <UnauthorisedUser message={"User is not set up"} />
          }
        }
      }
    />
  )
}

const UserDashboard = () => {
  const userSession = useSelector((state: any) => state.login.loginState.session);
  const userRoles = userSession?.idToken?.payload['cognito:groups'] || [];
  const engineerRole = userRoles.includes(config.engineeradgroup);
  const adminRole = userRoles.includes(config.adminadgroup);
  const backOfficerRole = userRoles.includes(config.backofficeadgroup);
  if (backOfficerRole || adminRole) {
    return <BackOfficeHome />
  } 
  else if (engineerRole){
    return <Home />
  }
}

export default () => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const dispatch = useDispatch();

  const authCheck = async () => {
    const auth: any = await isAuthenticated();
    const preferred_username = auth.session?.idToken?.payload?.preferred_username || "";
    let hasEngineerAccess: any;

    if (auth.authenticated){
      hasEngineerAccess = await engineerAccess(preferred_username, auth);
    }
    dispatch(engineerAccessAction(hasEngineerAccess));
    setAuthenticated(auth.authenticated);
    dispatch(saveLoginData(auth));
    setLoading(false);
  }

  const engineerAccess = async (preferred_username: string, auth: any) => {
    let idToken = auth.session?.idToken?.jwtToken || "";
    let headerconfig = {
      headers: {
        'Authorization': idToken,
        'Content-Type': 'application/json',
      }
    }

    const accessPromise = new Promise((resolve, reject) => {
      axios.put(Constants.onlineEndPoint, ({}), headerconfig)
        .then((response: any) => {
          if (response.status === 200) {
            dispatch(setNetworkState(true))
            const body = {
              operation: "registeruser",
              lanid: preferred_username,
            };
            apiRequest(Constants.toteEndPoint, JSON.stringify(body))
              .then((response: any) => {
                // console.log(response);
                const dataResponse: boolean = JSON.parse(response.data.response);
                resolve(dataResponse)
                // resolve(true)
              })
              .catch((error: any) => {
                resolve(false)
              });
          } else {

            dispatch(setNetworkState(false))
            resolve(false)
          }
          // console.log("response***", response)
        })
        .catch((error: any) => {
          dispatch(setNetworkState(false))
          // console.log("error***", error);
          const userRoles = auth.session?.idToken?.payload['cognito:groups'] || [];
          const engineerRole = userRoles.includes(config.engineeradgroup);
          const adminRole = userRoles.includes(config.adminadgroup);
          const backOfficerRole = userRoles.includes(config.backofficeadgroup);
          if (adminRole)
            resolve(false)
          else if (backOfficerRole)
            resolve(false)
          else if (engineerRole)
            resolve(true)
        });

    })
    return accessPromise;

  }

  const getIdToken = async () => {
    let idToken = new Promise((resolve, reject) => {
      Auth.currentSession().then(async (currentSession) => {
        let currentuser = await Auth.currentAuthenticatedUser();
        let refreshtoken = currentSession.getRefreshToken()
        currentuser.refreshSession(refreshtoken, (err: any, session: any) => {
          if (session != null && session != undefined) {
            let idToken = session.getIdToken().getJwtToken()
            resolve(idToken)
          } else {
            reject('ERROR')
          }
          if (err) {
            reject('ERROR')
          }
        })
      }).catch(() => {
        reject('ERROR')
      })
    })

    return idToken
  }

  const setApiInterval = () => {
    Auth.currentSession().then((currentsession) => {
      let idtkn = currentsession.getIdToken().getJwtToken()
      let headerconfig = { headers: { 'Authorization': idtkn } }
      axios.put(Constants.onlineEndPoint, ({}), headerconfig)
        .then((response: any) => {
          if (response.status === 200) {
            dispatch(setNetworkState(true))
          } else {
            dispatch(setNetworkState(false))
          }
          // console.log("response***", response)
        })
        .catch((error: any) => {
          try {
            if (error.response.status == 401) {
              getIdToken().then((token: any) => {
                let hconfig = { headers: { 'Authorization': token } }
                axios.put(Constants.onlineEndPoint, ({}), hconfig).then((response: any) => {
                  if (response.status === 200) {
                    dispatch(setNetworkState(true))
                  } else {
                    dispatch(setNetworkState(false))
                  }
                }).catch((err) => {
                  dispatch(setNetworkState(false))
                })
              }).catch(() => {
                dispatch(setNetworkState(false))
              })
            } else {
              dispatch(setNetworkState(false))
            }
          } catch (error) {
            dispatch(setNetworkState(false))
          }
          // console.log("error***", error);
        });
    })


  }

  useEffect(() => {
    authCheck();
    setApiInterval();

    const networkStatus = setInterval(() => {
      setApiInterval()
    }, 30000)
    
    return () => clearInterval(networkStatus)
  }, [])

  if (loading) {
    return <p>Loading ...</p>
  }

  return (
    <BrowserRouter>
      <Switch>
        <PrivateAuthControl path="/scantote" exact component={ScanTote} roles={[Roles.ADMIN, Roles.ENGINEER]} />
        <PrivateAuthControl path="/scanasset" exact component={ScanAsset} roles={[Roles.ADMIN, Roles.ENGINEER]} />
        <PrivateAuthControl path="/myinventory" exact component={MyInventory} roles={[Roles.ADMIN, Roles.ENGINEER]} />
        <PrivateAuthControl path="/search" exact component={Search} roles={[Roles.ADMIN, Roles.ENGINEER]} />
        <PrivateAuthControl path="/" exact component={UserDashboard} roles={[Roles.ADMIN, Roles.ENGINEER, Roles.BACKOFFICE]} />
        <PrivateAuthControl path="/home" exact component={UserDashboard} roles={[Roles.ADMIN, Roles.ENGINEER, Roles.BACKOFFICE]} />
        <PrivateAuthControl path="/userdetails" exact component={UserDetails} roles={[Roles.BACKOFFICE, Roles.ADMIN]} />
        <PrivateAuthControl path="/reports" exact component={Reports} roles={[Roles.BACKOFFICE, Roles.ADMIN]} />
        <PrivateAuthControl path="/createuser" exact component={CreateUser} roles={[Roles.BACKOFFICE, Roles.ADMIN]} />
      </Switch>
    </BrowserRouter>
  )
}