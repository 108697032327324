import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './rootReducer';

export default function configureStore () {
    const enhancer = [];
    const windowObj = typeof window === 'undefined' ? null: window as any;
    if (windowObj && windowObj.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancer.push(windowObj.__REDUX_DEVTOOLS_EXTENSION__())
    }

    return createStore(
        rootReducer,
        compose(applyMiddleware(thunk, ...enhancer))
    )
}