import styled from "styled-components";
import { TableRowProps } from "./types";

const InventoryTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  @media (max-width: 450px) {
    // border-collapse: unset;
  }
`;

const InventoryThead = styled.thead`
  background-color: #b9d7f7;
  color: #0061bb;

  @media (max-width: 450px) {
    display: none;
  }
`;

const InventoryTh = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
  text-align: center;

  @media (max-width: 450px) {
    padding: 0;
  }
`;

interface TableDataProps {
  dataLabel: string;
}

const InventoryTd = styled.td<TableDataProps>`
  border: 1px solid #dddddd;
  // text-align: left;
  padding: 5px;
  text-align: center;
  color: #ffffff;
  overflow: wrap;

  div {
    text-align: left;
    text-decoration: underline;
  }

  @media (max-width: 450px) {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    text-align: right;
    position: relative;
    border: 0;
    // min-height: 25px;

    ${({ id }) =>
      id === "slNo" &&
      `
    border-bottom: 1px dashed black;
    font-size: 15px;
    padding-top: 5px;
    `}

    div {
      text-align: right;
      // font-size: 17px;
    }

    span {
      max-width: 60%;
      overflow-x: scroll;
    }

    button {
      height: 25px;
      width: 25%;
      font-size: 13px;
    }

    ::before {
      position: absolute;
      left: 0;
      // width: 50%;
      padding-left: 5px;
      // font-size: 13px;
      font-width: bold;
      text-align: left;

      ${({ dataLabel }) =>
        dataLabel &&
        `
      content: "${dataLabel}";
      `}
    }
  }
`;
const InventoryTr = styled.tr<TableRowProps>`
  @media (max-width: 450px) {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border-radius: 5px;
  }

  ${({ bgColor }) =>
    bgColor &&
    `
      background-color: ${bgColor};

      // background-image: linear-gradient(to bottom right, ${bgColor}, grey);
    `}
`;

const ReasonSelectList = styled.select`
  border: 1px solid #dfe2e7;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-size: inherit;
  font-weight: bold;
  height: 60px;
  text-align: center;

  @media (max-width: 450px) {
    height: 20px;
    width: 50%;
    border: unset;
    border-radius: 3px;
  }
`;
const InputTextdiv = styled.div`
  margin-right: 20px;
  width: 35%;

  input {
    font-size: 18px;
    border-radius: 3px;
  }

  @media (max-width: 450px) {
    width: 40%;
    height: 30px;
    input {
      font-size: 13px;
    }
  }
`;

const InventoryTbody = styled.tbody`
  tr:nth-child(even) {
    // border: 1px dashed red;
  }

  @media (max-width: 450px) {
    display: block;
    width: 100%;
    padding: 10px;
  }
`;

export {
  InventoryTable,
  InventoryTh,
  InventoryTd,
  InventoryTr,
  InventoryThead,
  ReasonSelectList,
  InputTextdiv,
  InventoryTbody,
};
