import React, { useState, useEffect } from 'react';
import { StackContainer, StackLogo, StackTitle, NetworkState } from './styles';
import { StackHeaderProps } from './types';
import { useSelector } from 'react-redux';
import offlineImage from '../../app-assets/images/network_offline_icon.png';
import onlineImage from '../../app-assets/images/network_online_icon.png';
import edfIcon from '../../app-assets/images/edficon.png';
import config from '../../config';
import {Refreshmyinventory} from '../../pages/offline/myinventoryoffline';
import {Retryofflinerequest} from '../../pages/offline/pendingrequest';

const StackHeader: React.FC<StackHeaderProps> = (props) => {
    const { title } = props;
    const networkState = useSelector((state: any) => state.login.isOnline);
    const networkStatusImage = networkState ? onlineImage : offlineImage;
    const userSession = useSelector(
        (state: any) => state.login.loginState.session
      );
    const userRoles = userSession?.idToken?.payload['cognito:groups'] || [];
    const engineerRole = userRoles.includes(config.engineeradgroup);
    const adminRole = userRoles.includes(config.adminadgroup);
    const backOfficerRole = userRoles.includes(config.backofficeadgroup);

    const preferred_username =	userSession?.idToken?.payload?.preferred_username || "";
    useEffect(()=>{
		if (!adminRole && !backOfficerRole && engineerRole && networkState){
			Retryofflinerequest().then(()=>{
				Refreshmyinventory(preferred_username)
			})
		}
	})
    return(
        <StackContainer>
            <StackLogo src={edfIcon} data-testid = {'edfIcon'} />
            <StackTitle data-testid = {'stackTitle'}> {title}</StackTitle>
            { (!adminRole && !backOfficerRole && engineerRole) && (
                <NetworkState><img src={networkStatusImage} height='30px' data-testid = {'networkstateImage'} /></NetworkState>
            )}
            
        </StackContainer>
    )
}

export default StackHeader;