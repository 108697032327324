import React, { useState, useRef, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import {
  UserTab,
  UserDiv,
  InputTextImgDiv,
  InputTextField,
  UserMainDiv,
  InventoryTable,
  InventoryTh,
  InventoryTd,
  InventoryTr,
  InventoryThead,
  InventoryTbody,
  InventoryTrhead,
  DownloadCSVDiv,
  UserStatusSelectList,
  InputFieldCheckBox,
} from "./styles";
import AttachFileIcon from "../../app-assets/images/attachfile_icon.png";
import BulkuploadIcon from "../../app-assets/images/bulkupload_icon.png";
import SearchIcon from "../../app-assets/images/search_icon.png";
import CreateUserIcon from "../../app-assets/images/createuser_icon.png";
import EditIcon from "../../app-assets/images/edit_icon.png";
import DeleteIcon from "../../app-assets/images/delete_icon.png";
import { useHistory, useLocation } from "react-router-dom";
import apiRequest from "../../apirequest";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import ReactPaginate from "react-paginate";
import PopupModal from "../../components/PopupModal";
import "./pagination.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CSVIcon from "../../app-assets/images/csv_icon.png";
import { CSVLink } from "react-csv";
import PapaParse from "papaparse";
import UserDetailsSmallIcon from "../../app-assets/images/userdetails_small_icon.png";
import { InputTextdiv } from "../myinventory/styles";
toast.configure();

const UserDetails = () => {
  const breadCrumRoutes = [
    {
      name: "UserDetails",
      path: "/userdetails",
      icon: UserDetailsSmallIcon,
      testId: "UserDetails_Data",
    },
  ];

  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const history = useHistory();
  const [nameValue, setNameValue] = useState("");
  const [uploadFileData, setuploadFileData] = useState<any>({});
  const uploadRef: any = useRef(null);
  const [userListDetails, setuserListDetails] = useState([]);
  const [userListDetailsCount, setuserListDetailsCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const itemsPerPage = 10;
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowdelete] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState<any>([]);
  const location: any = useLocation();
  const [bulkUsersData, setBulkUsersData] = useState<any>([]);
  const [userStatusValue, setUserStatusValue] = useState<any>("");
  const [companyName,setCompanyName]=useState<string>('')
  const chunkSize=25;

  const handleCreateUser = () => {
    history.push("/createuser");
  };

  const handleNameValue = (event: any) => {
    const value = event.target.value;
    console.log("Name Search", value);
    setNameValue(value);
  };

  const handleSearchName = () => {
    console.log("Operative Name", nameValue);
    fetchUserListData();
  };

  const handleCompanySearch=(event:any)=>{
    setCompanyName(event.target.value)
  }

  const onSelectFile = (event: any) => {
    console.log("file", event.target.files[0]);

    setuploadFileData(event.target.files[0]);
    // console.log("path", URL.createObjectURL(event.target.files[0]));
    const formData: any = new FormData();
    formData.file = event.target.files[0];
    const options = {
      complete: (result: any) => {
        // console.log("result", result.data);
        if (
          result.data &&
          Array.isArray(result.data) &&
          result.data.length > 0
        ) {
          const csvData = result.data;
          const headersArray: any = csvData[0];
          csvData.splice(0, 1);
          const jsonData = csvData.map((row: any) => {
            const item: any = {};
            headersArray.forEach((header: string, index: number) => {
              item[header] = row[index];
            });

            return item;
          });
          // console.log("csvdatalength", csvData.length);
          if (csvData.length > 0) {
            setBulkUsersData(jsonData);
          } else {
            toast.warning("Empty File was uploaded");
          }
          // console.log("data JSOn", jsonData);
        }
      },
      skipEmptyLines: true,
    };
    PapaParse.parse(event.target.files[0], options);
    // console.log("file", formData);
  };

  useEffect(() => {
    fetchUserListData();
  }, []);

  useEffect(() => {
    getPageData();
  }, [itemOffset, userListDetails]);

  const getPageData = () => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setTableData(userListDetails.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(userListDetails.length / itemsPerPage));
  };

  console.log("tableData", tableData);
  const fetchUserListData = (searchAll: boolean = false) => {
    setItemOffset(0)
    setuserListDetails([]);
    setuserListDetailsCount(0);
    const body = {
      operation: "fetchuser",
      enggid: preferred_username,
      operativename: searchAll ? "" : nameValue,
      engineerstatus: searchAll ? "": userStatusValue,
      companyname: searchAll ? "": companyName
    };
    // console.log("body Data", body);
    setLoading(true);
    apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        setLoading(false);
        const dataResponse = JSON.parse(response.data.response);
        // const { data } =  response;
        setItemOffset(0);
        setuserListDetails(dataResponse);
        setuserListDetailsCount(dataResponse.length);
        console.log("Response***", dataResponse);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % userListDetails.length;
    setItemOffset(newOffset);
  };

  const handleUserStatus = (event: any) => {
    const value = event.target.value;
    setUserStatusValue(value);
  };

  const showAllUserDetails = () => {
    setNameValue("");
    setUserStatusValue("");
    fetchUserListData(true);
  };

  const hideDeleteButton = () => {
    setShowdelete(false);
  };

  const handleDeleteButton = () => {
    setShowdelete(false);
    console.log("deleted user data", selectedUserData);
    const userLanId: any = selectedUserData.lanid;
    const newSelectList = [...userListDetails];
    const itemIndex = newSelectList.findIndex(
      (item: any) => item.lanid === userLanId
    );
    // newSelectList.splice(itemIndex, 1);
    // setuserListDetails(newSelectList);

    // userListDetails.splice(userLanId);

    // return false;
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      operation: "deleteuser",
      enggid: preferred_username,
      lanid: selectedUserData.lanid,
    };
    console.log("body Data", body);
    setLoading(true);
    apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const dataResponse = JSON.parse(response.data.response);
        // const { data } =  response;
        newSelectList.splice(itemIndex, 1);
        setuserListDetails(newSelectList);
        if (dataResponse) {
          toast.success(dataResponse);
        } else {
          toast.warning("Delete was failed");
        }

        console.log("Response***", dataResponse);
      })
      .catch((error: any) => {
        setLoading(false);
        console.log("error", error);
      });
  };

  const handleDeleteUserBtn = (arraydata: any) => {
    console.log("selected user data", arraydata);
    setSelectedUserData(arraydata);
    setShowdelete(true);
  };

  const handleEditUserBtn = (arraydata: any) => {
    console.log(arraydata);
    history.push({
      pathname: "./createuser",
      state: {
        userEditData: arraydata,
      },
    });
  };

  const handleUploadUsers = async () => {
    console.log(bulkUsersData.length);
    // return false;
    let itrsize = 0;
    let successcount  = 0;
    let errorcount = 0;
    if(bulkUsersData.length > 0){
      itrsize = Math.ceil(bulkUsersData.length/chunkSize)
      for (let i = 0; i<itrsize ; i++){
        let startidx = i * chunkSize
        let endidx = Math.min((i+1)*chunkSize,bulkUsersData.length)
        let sliceddata = bulkUsersData.slice(startidx,endidx)
        // console.log(startidx,endidx)
        // console.log(sliceddata.length)
        const body = {
          operation: "bulkupdate",
          enggid: preferred_username,
          bulkrecords: sliceddata,
        };
        // console.log("body Data", body);
        // console.log('upload started for chunk ',i+1)
        setLoading(true);
        await apiRequest(Constants.toteEndPoint, JSON.stringify(body))
          .then((response: any) => {
            // console.log(response);
            const dataResponse = JSON.parse(response.data.response);
            // console.log("Response***", dataResponse);
            if (dataResponse) {
              // toast.success(dataResponse);
              successcount = successcount + sliceddata.length
            } else {
              errorcount = errorcount + sliceddata.length
              // toast.warning("User Upload failed");
            }
          })
          .catch((error: any) => {
            // setLoading(false);
            // toast.warning("User Upload failed");
            errorcount = errorcount + sliceddata.length
            console.log("error", error);
          });
          // console.log('upload finished for chunk ',i+1)

          if(i == itrsize -1){
            setLoading(false);
            setuploadFileData("");
            if (errorcount == 0){
              toast.success("All the users are successfully uploaded");
            }else{
              toast.warning(successcount + " users successfully uploaded, "+ errorcount + " users failed to upload");
            }
            fetchUserListData();
          }
      }
    }
    
    
  };

  const headers = [
    { label: "Name", key: "operativename" },
    { label: "LanID", key: "lanid" },
    { label: "UIN", key: "uin" },
    { label: "District", key: "district" },
    { label: "ContactNumber", key: "contactnumber" },
    { label: "EngineerStatus", key: "engineerstatus" },
    { label: "CompanyName", key: "companyname"}
  ]; 

  return (
    <>
      <PopupModal
        show={showDelete}
        handleClose={hideDeleteButton}
        primaryAction={handleDeleteButton}
        yestestId={"deleteYesButton"}
        notestId={"deleteNoButton"}
        testMsg1={"Are you sure to delete user ? "}
        testMsg2={` Username - ${selectedUserData.operativename} `}
        data-testid="deletemodal"
      ></PopupModal>
      <BreadCrumb inputRoutes={breadCrumRoutes} />
      <Container wide>
        <Card>
          <UserMainDiv>
            <UserDiv>
              <InputTextImgDiv>
                <img
                  src={AttachFileIcon}
                  onClick={() =>
                    uploadRef.current && uploadRef.current?.click()
                  }
                  height="40px"
                  width="30px"
                  title="upload"
                />
                {uploadFileData.name}
                <input
                  type="file"
                  onChange={onSelectFile}
                  value=""
                  hidden
                  ref={uploadRef}
                  accept=".csv"
                  data-testid="bulkinput"
                />
              </InputTextImgDiv>
              <UserTab data-testid="bulkupload" onClick={handleUploadUsers}>
                <img src={BulkuploadIcon} height="30px" />
                &nbsp; Bulk Upload
              </UserTab>
            </UserDiv>
            <UserDiv>
              <UserTab data-testid={"createuserbtn"} onClick={handleCreateUser}>
                <img src={CreateUserIcon} height="30px" />
                &nbsp; Create User
              </UserTab>
            </UserDiv>
            <UserDiv>
              <UserStatusSelectList data-testid={'engg_status'}
                onChange={handleUserStatus}
                value={userStatusValue}
              >
                <option value="">All Users</option>
                <option value="ACTIVE">Active Users</option>
                <option value="INACTIVE">Inactive Users</option>
                <option value="DORMANT">Dormant Users</option>
              </UserStatusSelectList>
              &nbsp;
              <InputTextField
                style={{marginRight:'5px'}}
                placeholder="Name"
                onChange={handleNameValue}
                value={nameValue}
                data-testid={"searchinput"}
              />
              <InputTextField 
              placeholder="Company Name"
              onChange={handleCompanySearch}
              value={companyName}
              data-testid={"companyinput"}
              />
              
              <UserTab data-testid={"searchbtn"} onClick={handleSearchName}>
                <img src={SearchIcon} height="30px" />
                &nbsp; Search
              </UserTab>
              &nbsp;
              <UserTab data-testid={"showallbtn"} onClick={showAllUserDetails}>Show All</UserTab>
            </UserDiv>
          </UserMainDiv>
          <DownloadCSVDiv>
            {/* <CSVLink
              filename={"Bulk_CreateUsers.csv"}
              headers={headers}
              data={[]}
              style={{ textDecoration: "none" }}
            >
              <img src={CSVIcon} height="30px" /> Download Sample CSV for Bulk
              Upload{" "}
            </CSVLink> */}

            <CSVLink
              filename={`Userslist_${Date.now()}.csv`}
              headers={headers}
              data={userListDetails}
              style={{ textDecoration: "none" }}
            >
              <img src={CSVIcon} height="30px" /> Download CSV
            </CSVLink>
          </DownloadCSVDiv>
          <div style={{ marginTop: "20px" }}>
            {isLoading && (
              <div style={{ position: "absolute", left: 0, right: 0 }}>
                <LoadingIndicators
                  size="lg"
                  position="center"
                  colour="gray"
                  type="circle"
                />
              </div>
            )}
            <InventoryTable>
              <InventoryThead>
                <InventoryTrhead>
                <InventoryTh>UIN</InventoryTh>
                  <InventoryTh>Name</InventoryTh>
                  <InventoryTh>Lan - ID</InventoryTh>
                  <InventoryTh>District</InventoryTh>
                  {/* <InventoryTh>Working District</InventoryTh> */}
                  <InventoryTh>Phone Number</InventoryTh>
                  <InventoryTh>Company Name</InventoryTh>
                  <InventoryTh>Status</InventoryTh>
                  <InventoryTh>Action</InventoryTh>
                </InventoryTrhead>
              </InventoryThead>
              <InventoryTbody>
                {tableData &&
                  Array.isArray(tableData) &&
                  tableData.map((item: any, index: number) => {
                    return (
                      <InventoryTr key={item.lanid}>
                        <InventoryTd>{item.uin}</InventoryTd>
                        <InventoryTd>{item.operativename}</InventoryTd>
                        <InventoryTd>{item.lanid}</InventoryTd>
                        <InventoryTd>{item.district}</InventoryTd>
                        {/* <InventoryTd>{item.working_district}</InventoryTd> */}
                        <InventoryTd>{item.contactnumber}</InventoryTd>
                        <InventoryTd>{item.companyname}</InventoryTd>
                        <InventoryTd>
                          {item.engineerstatus}
                        </InventoryTd>
                        <InventoryTd>
                          <div>
                            <img
                              src={EditIcon}
                              height="20px"
                              width="20px"
                              title="Edit"
                              onClick={() =>
                                handleEditUserBtn && handleEditUserBtn(item)
                              }
                              style={{ cursor: "pointer",left:0,marginRight:"30px" }}
                            />
                            <img
                              src={DeleteIcon}
                              height="20px"
                              width="20px"
                              title="Delete"
                              onClick={() =>
                                handleDeleteUserBtn && handleDeleteUserBtn(item)
                              }
                              style={{ cursor: "pointer" }}
                              data-testid={"deleteuser"+ item.lanid}
                            />{" "}
                          </div>
                        </InventoryTd>
                      </InventoryTr>
                    );
                  })}
              </InventoryTbody>
            </InventoryTable>
            {tableData && Array.isArray(userListDetails) && pageCount > 1 && (
              <div className="user_pagi">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< Previous"
                  marginPagesDisplayed={2}
                />
              </div>
            )}
          </div>
        </Card>
      </Container>
    </>
  );
};

export default UserDetails;