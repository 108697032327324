import { Auth } from "aws-amplify";
import axios from "axios";

const apiRequest = (endpoint: string, body: string) => {
    let apiresponse = new Promise((resolve, reject) => {
        Auth.currentAuthenticatedUser().then((currentuser) => {
            Auth.currentSession().then((currentSession) => {
                let refreshtoken = currentSession.getRefreshToken()
                currentuser.refreshSession(refreshtoken, (err: any, session: any) => {
                    if (session != null && session != undefined) {
                        let idToken = session.getIdToken().getJwtToken()
                        let config = {
                            headers: {
                                'Authorization': idToken,
                                'Content-Type': 'application/json',
                            }
                        }
                        axios.put(endpoint, (body), config).then((response: any) => {
                            resolve(response)
                        }).catch((err) => {
                            reject(err)
                        })
                    }else{
                        reject('Session Undefined')
                    }
                })
            }).catch((err) => {
                reject(err)
            });

        }).catch((err) => {
            reject(err)
        })

    })
    return apiresponse
}

export default apiRequest;
