import React from 'react';

import GlobalStyle from '../../utils/styles';

interface WallaceProps {
  children: React.ReactNode;
}

const Wallace: React.FC<WallaceProps> = (props) => {
  const { children } = props;
  return (
    <>
      <GlobalStyle />
      {children}
    </>
  );
};

export default Wallace;
