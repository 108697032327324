import { CameraBox } from "./style";

const CameraWindow = () => {
  return (
    <CameraBox>
      <div id="scan-header">
        <p>Scanning in Progress..</p>
        <span>Focus the barcode inside the red box</span>
      </div>
      <div id="camera-div">
        <div className="motion-window">
          <video id="camera-window"></video>
          <img id="switch-camera" src={`/assets/images/camera_toggle.png`} />
          <div id="scanner-red-bar"></div>
        </div>
      </div>
      <button id="close-camera">Close</button>
      <div id="test_cam"></div>
    </CameraBox>
  );
};

export default CameraWindow;
