import styled from "styled-components";

const InventoryTable = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const InventoryThead = styled.thead`
   background-color : #b9d7f7;
   color : #0061bb;
`;

const InventoryTh = styled.th.attrs({
  rowSpan: 2
})`
  font-size: 13px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
  text-align:center;
`;

const SplitHeader = styled.th.attrs({
  colSpan: 2
})`
  font-size: 13px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 5px;
  text-align:center;
`;

const InventoryTd = styled.td`
  font-size: 12px;
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
  text-align:center;
`;
const InventoryTr = styled.tr`
   
`;

const InventoryTrhead = styled.tr`
  
`;

const InventoryTbody = styled.tbody`
   
`;
const InputFieldsDiv = styled.div`
   font-size: 12px;
   margin-right: 5px;
   padding: 0;
  //  border: 1px solid red;
   input{
     height:50px;
     width: 100px;
   }
`;

const DatePickerStyled = styled.div`
  /* STYLE FOR WITH */
  .react-datepicker-wrapper>.react-datepicker__input-container input {
    // all: unset !important;
    width: 120px;
    height:50px;
}
`;
const TextDivTag = styled.div`
padding: 5px;
`;
const AssetStatusSelectList = styled.select`
  border: 1px solid #dfe2e7;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-size: inherit;
  // font-weight: bold;
  height:50px;
  text-align:center;
`;

const ProgressBar = styled.progress`
  width: 250px;
  height: 20px;
`;

export { InventoryTable, InventoryTh, SplitHeader, InventoryTd, InventoryTr, InventoryThead, InventoryTbody, InventoryTrhead, InputFieldsDiv, DatePickerStyled, TextDivTag, AssetStatusSelectList, ProgressBar };