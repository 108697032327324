import { CameraButton } from "./style";
import { CameraScannerProps } from "./types";
import cameraIcon from "../../app-assets/images/camera_1.png";

import { BrowserMultiFormatReader, DecodeHintType } from "@zxing/library";

const CameraScanner: React.FC<CameraScannerProps> = (props) => {
  const { handleCamWindow, handleScan, handleCamToggle } = props;

  const hints = new Map(); // hints for the Multiformat Reader
  // const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX /*, ...*/];
  const formats = [1, 2, 3, 4, 6, 7, 11, 14, 15]; // enum for different types of code, mostly 1D
  const codeReader = new BrowserMultiFormatReader(hints);

  hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

  // hints.set(DecodeHintType.TRY_HARDER, true);

  // scans the barcode

  function getbackCameraList(inputdevices:MediaDeviceInfo[]){
    let backcameralist = new Promise((resolve,reject)=>{
      let blist:number[] =[]
      let i = 0;
      inputdevices.forEach((item: any, index: number) => {
        i = i+1
        if (item.label.toLowerCase().includes('back')) {
          blist.push(index);
        }
        if (i == inputdevices.length){
          resolve(blist)
        }
      });
    })
    return backcameralist;
  }

  const scanCode = async (camID: number, nextFlag: boolean) => {
    handleCamToggle && handleCamToggle(false);

    const videoInputDevices = await codeReader.listVideoInputDevices();
    const scanTimeout = setTimeout(()=>{
      codeReader.reset();
      handleScan && handleScan(''); // send the barcode to the Asset/Tote pages..
      handleCamWindow && handleCamWindow(false); // close the camera UI
    },20000)

    if (!nextFlag) {
      // always begin with the last camera module available. 
      camID = videoInputDevices.length - 1;
    }
    
    if (videoInputDevices.length == 0) {
      // if no camera module found, return false
      const scanHeader = document.getElementById("scan-header");
      scanHeader!.innerHTML = "<h4>No Camera Module Detected</h4>";

      const closeCameraButton = document.getElementById("close-camera");
      closeCameraButton?.addEventListener("click", () => {
        handleCamWindow && handleCamWindow(false);
      });

      return false;
    }
    else if (videoInputDevices.length == 1) {
      // if only one camera is detected, use it!
      camID = 0;
    }
    // stops the scanning when "Close" button is clicked
    const closeCameraButton = document.getElementById("close-camera");
    closeCameraButton?.addEventListener("click", () => {
      codeReader.reset();
      handleCamWindow && handleCamWindow(false);
    });

    getbackCameraList(videoInputDevices).then(async (backCameras:any)=>{
      // toggle between multiple rear cameras
      if (backCameras.length > 1) {
        const toggleCamera = document.getElementById("switch-camera");
        toggleCamera!.style.display = "block";

        toggleCamera?.addEventListener("click", () => {
          codeReader.reset();
          let nextCam = camID + 1;
          if ((camID + 1) == videoInputDevices.length) {
            nextCam = backCameras[0];
          }
          handleCamToggle && handleCamToggle(true);
          handleCamera(nextCam, true);
        });
      }

      
      const selectedDevice = videoInputDevices[camID];
      try {
        const vconstraints = {
          deviceId:{exact:selectedDevice.deviceId},
          width:{min:640,ideal:1280},
          height:{min:480,ideal:720},
        }
        const constraints : MediaStreamConstraints = {video:vconstraints}
        await codeReader.decodeFromConstraints(constraints,"camera-window",(result, error) => {
          // if success, then call the "handleScan(barcode)" callback function
          if (result) {
            codeReader.reset(); // stops camera
            const barcode = result.getText();
            handleScan && handleScan(barcode); // send the barcode to the Asset/Tote pages..
            clearTimeout(scanTimeout);
            handleCamWindow && handleCamWindow(false); // close the camera UI
          }
          // if error (other than unable to get barcode), log the error to browser console..
          if (error && !error.message.includes("NotFoundException")) {
            console.log("Error: " + error.name);
            console.log("Error Message: " + error.message);
          }
        })
      } catch (err) {
        let errorMessage = "";
  
        if (err instanceof Error) {
          errorMessage = err.message;
  
          // what if user does not approve camera permisson by mistake, need to handle it
  
          if (errorMessage == "Permission denied") {
            const errorHeader = document.getElementById("scan-header");
            errorHeader!.innerHTML = "<h4>Can't Access Camera Module</h4>";
  
            const errorRemedyDiv = document.getElementById("camera-div");
            errorRemedyDiv!.innerHTML =
              "<p>Permission Denied: Allow camera access from settings and then reload</p>";
          }
  
          return false;
        }
      }
    })

    

    // renders the scanning animation red bar
    // const scanner_bar = document.getElementById("scanner-red-bar");
    // scanner_bar!.style.display = "block";
  };

  const handleCamera = (camID: number, nextFlag: boolean = false) => {
    handleCamWindow && handleCamWindow(true); // open the camera UI
    scanCode(camID, nextFlag); // initiates code scanning
  };

  return (
    <CameraButton onClick={() => handleCamera(1)}>
      <img src={cameraIcon} />
      <span>Scan</span>
    </CameraButton>
  );
};

export default CameraScanner;
