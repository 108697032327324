import React, { useEffect } from 'react';
import Card from '../../components/Card';
import Container from '../../components/Container';
import HomeScreenIcon from '../../components/HomeScreenIcons';
import Grid from '../../components/Grid';
import BreadCrumb from '../../components/BreadCrumb';
import { useHistory } from 'react-router-dom';
import scanToteIcon from '../../app-assets/images/scantoteicon.png';
import scanAssetIcon from '../../app-assets/images/scanasseticon.png';
import myInventoryIcon from '../../app-assets/images/myinventoryicon.png';
import searchIcon from '../../app-assets/images/searchicon.png';





const HomeScreen = () => {
	const history = useHistory();
	const handleScanTote = () => {
		history.push('/scantote');
	}
	const handleScanToAsset = () => {
		history.push('/scanasset');
	}
	const handleMyInventory = () => {
		history.push('/myinventory');
	}
	const handleSearch = () => {
		history.push('/search');
	}
	
	
	

	

	return (
		<>
			<BreadCrumb inputRoutes={[]} />
			<Container>
				<div id="home_container_div" style={{ marginTop: '50px' }}>
					<Card>
						<Grid row>
							<Grid column md={3} sm={12}>
								<HomeScreenIcon
									imageName={scanToteIcon}
									name={"Scan Tote"}
									action={handleScanTote}
									bgColor="#333f50"
									contentTestId={'scanToteText'}
								/>
							</Grid>
							<Grid column md={3} sm={12}>
								<HomeScreenIcon
									imageName={scanAssetIcon}
									name={"Scan Asset"}
									action={handleScanToAsset}
									bgColor="#5b9bd5"
									contentTestId={'scanAssetText'}
								/>
							</Grid>
							<Grid column md={3} sm={12}>
								<HomeScreenIcon
									imageName={myInventoryIcon}
									name={"My Inventory"}
									action={handleMyInventory}
									bgColor="#bf9000"
									contentTestId={'myInventoryText'}
								/>
							</Grid>
							<Grid column md={3} sm={12}>
								<HomeScreenIcon
									imageName={searchIcon}
									name={"Search"}
									action={handleSearch}
									bgColor="#c55a11"
									contentTestId={'searchText'}
								/>
							</Grid>
						</Grid>
					</Card>
				</div>
			</Container>
		</>
	);
};

export default HomeScreen;
