import React from 'react';
import Wallace from '../Wallace';
import { TileBlock, TileWrapper, WhiteText, Icon } from './style';
import { HomeScreenIconProps } from './types';

const HomeScreenIcon: React.FC<HomeScreenIconProps> = (props) => {
    const { imageName, name, action, bgColor, contentTestId } = props;
    return (
        <Wallace>
            <TileWrapper onClick={action}>
                <TileBlock bgColor={bgColor}>
                    <Icon src={imageName} width={100} height={100}></Icon>
                    <WhiteText data-testid={contentTestId}>{name}</WhiteText>
                </TileBlock>
            </TileWrapper>
        </Wallace>
    )
}

export default HomeScreenIcon;
