import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import ScanScreen from "../../components/ScanScreen";
import Button from "../../components/button";
import CameraScanner from "../../components/CameraScanner";
import CameraWindow from "../../components/CameraWindow";
import { useHistory, useLocation } from "react-router-dom";
import PopupModal from "../../components/PopupModal";
import ReasonCodeIcon from "../../app-assets/images/reason_code_icon.png";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Tab,
  AssetStatus,
  AgingMessage,
  AgingImage,
  AgingMsgContent,
  AssetButtons,
  ButtonSection,
  CheckBoxdata,
  StatusContent,
  AssetScandiv,
  ReasonCodeImage,
  ReasonDropIcon,
  ReasonCodeList,
  ReasonSelectList,
} from "./styles";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import apiRequest from "../../apirequest";
import {
  Searchofflineasset,
  Updateofflineinventory,
} from "../offline/myinventoryoffline";
import { Insertofflinerequest } from "../offline/pendingrequest";
import {
  Fetchofflinereasoncode,
  RefreshReasoncode,
} from "../offline/reasoncode";
import ScanAssetSmallIcon from "../../app-assets/images/scan_asset_small_icon.png";
import CollectAssetSmallIcon from "../../app-assets/images/small_collect_asset_icon.png";
import InstallAssetSmallIcon from "../../app-assets/images/small_install_icon.png";
import ReturnAssetSmallIcon from "../../app-assets/images/small_return_icon.png";
import scanAssetIcon from '../../app-assets/images/scanasseticon.png';

enum ScanAssetType {
  INSTALL = "INSTALL",
  COLLECTASSET = "COLLECTASSET",
  RETURN = "RETURN",
}

const ScanAsset = () => {
  const breadCrumRoutes = [
    {
      name: "Scan Asset",
      path: "/scanasset",
      icon: ScanAssetSmallIcon,
      testId: "ScanAsset_Data",
    },
  ];

  const instructions = [
    "Place cursor into (text box)",
    "Scan asset using A2 option on Toughpad, or press “scan” button using mobile version",
    "You can manually type without scanning",
    "Serialised Assets : (example below)",
  ];

  const subInstructions = [
    // "Meter - Serial number",
    // "Comms hub - Serial GUID",
    // "Regulator - Regulator Barcode",
    "For Meters scan Serial Number",
    "For Comms hub scan GUID",
    "For IHD Geo scan serial number",
    "For IHD Chameleon scan GUID",
    "Regulator – Regulator Barcode"
  ];

  const history = useHistory();
  const location: any = useLocation();
  const [installAsset, setInstallAsset] = useState(ScanAssetType.INSTALL);
  const [assetnumber, setAssetNumber] = useState(
    location?.state?.assetSerialNumber || ""
  );
  const [show, setShow] = useState(false);
  const [cameraWindow, setCameraWindow] = useState(false);
  const [isSuccess, setSucessAsset] = useState(false);
  const [isAssetFound, setIsAssetFound] = useState(false);
  const [isWarning, setWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessAsset, setSuccessAsset] = useState(false);
  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const [isLoading, setLoading] = useState(false);
  const [showinventory, setShowinventory] = useState(false);
  const [asset, setAsset] = useState<any>({});
  const [agingMessage, setAgingMessage] = useState(
    "Newest Asset - Check my Inventory"
  );
  const [agingMsgColor, setAgingMsgColor] = useState("#ff0000");
  const [apiReasonCodes, setapiReasonCodes] = useState([]);
  const [showBackButton, setShowBackButton] = useState(false);
  const [reasonCodeValue, setReasonCodeValue] = useState("");
  const [collectDisabledTabs, SetCollectDisableTabs] = useState(false);
  const [scanOutDisabledTabs, SetScanOutDisableTabs] = useState(false);
  const [returnDisabledTabs, SetReturnDisableTabs] = useState(false);
  const [agingMsgDisplay, SetAgingMsgDisplay] = useState("");
  const isOnline = useSelector((state: any) => state.login.isOnline);
  const [assetValidation, setAssetValidation] =useState(false);

  const showModal = () => {
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  const handleHomeButton = () => {
    if (assetnumber) {
      setShow(true);
      return true;
    }
    return false;
  };

  const onPressHome = () => {
    if (!handleHomeButton()) {
      history.push("/home");
      // history.goBack();
    }
  };

  const handleAssetNumber = (event: any) => {
    // setIsAssetFound(true);

    const value = event.target.value;
    const regExp = /^[a-zA-Z0-9\s]*$/;
    if (value.length <= 30 && (value == "" || value.match(regExp))) {
      setWarning(false);
      setSuccessAsset(false);
      setErrorMessage("");
      setAssetNumber(value);
      // if(value.length >= 9) {
      //   verifyAsset(value);
      // }
    }
  };

  useEffect(() => {
    if (assetnumber) {
      verifyAsset();
    }
  }, []);

  const verifyAsset = (searchAll: boolean = false) => {
    if (assetnumber.toUpperCase().match(/^RIE/) || assetnumber.toUpperCase().match(/^TOTE/)){
      // will show error if the asset no begins with RIE or TOTE
      setWarning(true);
      setErrorMessage("Unable to scan TOTE using this function - Please use 'Scan Tote' tile to collect the Tote into your inventory.");
      setAssetValidation(false);
      return false;
    }
    else{
      setAssetValidation(true);
    }

    if (isOnline == false) {
      Searchofflineasset(assetnumber)
        .then((response: any) => {
          afterassetsearch(response);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      const config = {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        assetcode: searchAll ? "" : assetnumber,
        operation: "fetchasset",
        enggid: preferred_username,
      };
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          afterassetsearch(response);
        })
        .catch((error) => {
          Searchofflineasset(assetnumber)
            .then((response: any) => {
              setLoading(false);
              afterassetsearch(response);
            })
            .catch((error) => {
              setLoading(false);
            });
        });
    }
  };

  const afterassetsearch = (response: any) => {
    console.log(response);
    setLoading(false);
    const dataResponse = JSON.parse(response.data.response);
    let assetMessage = "";
    let asset_status = "";
    let asset_age = "0";
    let reason_id = "";
    let reason_code = "";
    setAsset(dataResponse[0]);

    if (Array.isArray(dataResponse) && dataResponse.length > 0) {
      // assetMessage = `${dataResponse.length} Assets found`;
      // setSuccessAsset(true);
      setWarning(false);
      asset_status = dataResponse[0].asset_status;
      asset_age = dataResponse[0].asset_age;
      reason_id = dataResponse[0].reasonid;
      reason_code = dataResponse[0].reasond;
      if (reason_id == "R003") {
        setReasonCodeValue(reason_id);
      }
      setErrorMessage(assetMessage);
      setIsAssetFound(true);
      setassetStatausfunc(asset_status, reason_id, reason_code);
      setassetAgeFunc(asset_age);
    } else {
      assetMessage = "Asset is not in your Inventory, Do you want to continue?";
      setWarning(true);
      SetCollectDisableTabs(true);
      SetReturnDisableTabs(false);
      SetScanOutDisableTabs(true);
      setErrorMessage(assetMessage);
    }
    console.log("response", dataResponse, response);
  };

  const setassetStatausfunc = (
    asset_status: any,
    reason_id: any,
    reason_code: any
  ) => {
    switch (asset_status) {
      case "Collected":
        if (reason_id == "R003" && reason_code == "Recalled") {
          onInstallAssetCategory(ScanAssetType.RETURN);
          SetCollectDisableTabs(true);
          SetReturnDisableTabs(false);
          SetScanOutDisableTabs(true);
        } else {
          onInstallAssetCategory(ScanAssetType.INSTALL);
          SetCollectDisableTabs(true);
          SetReturnDisableTabs(false);
          SetScanOutDisableTabs(false);
        }
        break;
      case "Scan Out":
        if (reason_id == "R003" && reason_code == "Recalled") {
          onInstallAssetCategory(ScanAssetType.RETURN);
          SetCollectDisableTabs(true);
          SetReturnDisableTabs(false);
          SetScanOutDisableTabs(true);
        } else {
          onInstallAssetCategory(ScanAssetType.RETURN);
          SetCollectDisableTabs(true);
          SetScanOutDisableTabs(true);
          SetReturnDisableTabs(false);
        }
        break;
      case "Shipped":
        if (reason_id == "R003" && reason_code == "Recalled") {
          onInstallAssetCategory(ScanAssetType.RETURN);
          SetCollectDisableTabs(true);
          SetReturnDisableTabs(false);
          SetScanOutDisableTabs(true);
        } else {
          onInstallAssetCategory(ScanAssetType.COLLECTASSET);
          SetScanOutDisableTabs(true);
          SetReturnDisableTabs(true);
          SetCollectDisableTabs(false);
        }
        break;
      case "Unknown":
        onInstallAssetCategory(ScanAssetType.COLLECTASSET);
        SetScanOutDisableTabs(true);
        SetReturnDisableTabs(false);
        SetCollectDisableTabs(false);
        break;
      case "Returned":
        setIsAssetFound(false);
        setWarning(true);
        let assetMessage = `Asset Number ${assetnumber} is already Returned`;
        setErrorMessage(assetMessage);
        setAssetNumber("");
        break;
      default:
        onInstallAssetCategory(ScanAssetType.COLLECTASSET);
        SetScanOutDisableTabs(true);
        SetReturnDisableTabs(false);
        SetCollectDisableTabs(false);
        break;
    }
  };

  const setassetAgeFunc = (asset_age: any) => {
    const age = parseInt(asset_age);
    let aging_message = "Newest Asset - Check my Inventory";
    let aging_msg_color = "#ff0000";
    if (age > 10) {
      aging_message = "Oldest Asset - Please use for install";
      aging_msg_color = "#4f9e30";
    } else if (age > 5) {
      aging_message = "Older Asset Available - Check my Inventory";
      aging_msg_color = "#f57f17";
    } else if (asset_age == "-1") {
      SetAgingMsgDisplay("none");
    } else {
      aging_message = "Newest Asset - Check my Inventory";
      aging_msg_color = "#ff0000";
    }
    setAgingMessage(aging_message);
    setAgingMsgColor(aging_msg_color);
  };

  const onInstallAssetCategory = (type: ScanAssetType) => {
    setInstallAsset(type);
    setErrorMessage("");
    setWarning(false);
    setSuccessAsset(false);
  };
  const handleHomePage = () => {
    history.push("/home");
  };

  const onResetBtn = () => {
    setWarning(false);
    setErrorMessage("");
    setAssetNumber("");
  };

  const handleBackbutton = () => {
    // setIsAssetFound(false);
    // setWarning(false);
    // setErrorMessage("");
    // setAssetNumber("");
    setShowBackButton(true);
    // history.push("/scanasset");
  };

  const clickMyInventoryButton = () => {
    setShowinventory(true);
    // history.push("/myinventory");
  };

  const hadleMyInventory = () => {
    // history.push("/myinventory", [{ skuCode: asset?.skucode }]);
    history.push({
      pathname: "./myinventory",
      state: {
        skuCode: asset?.skucode,
      },
    });
  };

  const hideinventory = () => {
    setShowinventory(false);
  };

  const changeAssetStatus = (operation: string) => {
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      assetcode: assetnumber,
      operation: operation,
      enggid: preferred_username,
      scan_time: new Date(),
      reasoncode: reasonCodeValue,
    };
    if (isOnline == false) {
      Updateofflineinventory(body).then((response: any) => {
        afterstatusupdate(response);
        let offlinebody = {
          body: body,
          apipath: "toteasset",
          httpmethod: "PUT",
        };
        Insertofflinerequest(offlinebody).then((res) => {
          console.log(res);
        });
      });
    } else {
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          // const dataResponse = JSON.parse(response.data);
          afterstatusupdate(response);
          Updateofflineinventory(body);
        })
        .catch((error: any) => {
          Updateofflineinventory(body)
            .then((response: any) => {
              setLoading(false);
              afterstatusupdate(response);
              let offlinebody = {
                body: body,
                apipath: "toteasset",
                httpmethod: "PUT",
              };
              Insertofflinerequest(offlinebody).then((res) => {
                console.log(res);
              });
            })
            .catch(() => {
              setLoading(false);
            });
          console.log("error", error);
        });
    }
  };

  const afterstatusupdate = (response: any) => {
    console.log(response);
    const { data } = response;
    let reason_id = "";
    let reason_code = "";
    setassetStatausfunc(data.asset_state, reason_id, reason_code);
    if (data.state == "error") {
      setWarning(true);
      setErrorMessage(data.message);
    } else {
      setWarning(false);
      setErrorMessage(data.message);
    }
    let assetDetails = {
      asset_age: data.asset_age,
      asset_status: data.asset_state,
      skucode: data.skucode,
    };

    setassetAgeFunc(data.asset_age);
    setAsset(assetDetails);
    setSuccessAsset(true);
    // setErrorMessage(data.message);
    // setWarning(false);
    setLoading(false);
    setReasonCodeValue("");
  };

  const onContinueButton = () => {
    setIsAssetFound(true);
    setWarning(false);
    setErrorMessage("");
    onInstallAssetCategory(ScanAssetType.COLLECTASSET);
    SetScanOutDisableTabs(true);
    SetReturnDisableTabs(true);
    SetCollectDisableTabs(false);
  };

  // const handleCheckboxData = (event: any) => {
  //   // setIsAssetFound(true);

  //   const value = event.target.checked;
  //   console.log(value);
  // };

  const handleReasonCode = (event: any) => {
    // setIsAssetFound(true);

    const value = event.target.value;
    setReasonCodeValue(value);
  };

  useEffect(() => {
    fetchReasonCodes();
  }, []);

  const fetchReasonCodes = () => {
    if (isOnline == false) {
      Fetchofflinereasoncode().then((dataResponse: any) => {
        setapiReasonCodes(dataResponse);
      });
    } else {
      const config = {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        operation: "reasoncode",
        enggid: preferred_username,
      };
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          const dataResponse = JSON.parse(response.data.response);
          // const { data } =  response;
          setapiReasonCodes(dataResponse);
          // console.log(dataResponse);
          RefreshReasoncode(dataResponse);
        })
        .catch((error: any) => {
          console.log("error on reason code fetch");
        });
    }
  };

  const handlePopupBackbutton = () => {
    setIsAssetFound(false);
    setWarning(false);
    setErrorMessage("");
    setAssetNumber("");
    // history.push("/scanasset");
    hideBackButton();
  };

  const hideBackButton = () => {
    setShowBackButton(false);
  };

  const renderInstallAssetFields = () => {
    return (
      <>
        <AssetStatus> {asset?.asset_status || "Unknown"}</AssetStatus>
        <AgingMessage
          style={{
            display: `${agingMsgDisplay}`,
            backgroundColor: `${agingMsgColor}`,
          }}
        >
          <AgingMsgContent style={{ color: "#ffffff" }}>
            {agingMessage}
          </AgingMsgContent>
        </AgingMessage>
        <AssetButtons>
          {/* <Button
            type="button"
            label="Retry"
            size="lg"
            testId={"SaveButton"}
            onClick={showModal}
            bgColor="#4f9e30"
            textColor="#000000"
          /> */}
          <Button
            type="button"
            label="Save"
            size="lg"
            testId={"InstallSaveButton"}
            onClick={() => changeAssetStatus("install_asset")}
            bgColor="#4f9e30"
            textColor="#000000"
          />
          <Button
            type="button"
            label="My Inventory"
            size="lg"
            testId={"MyInventoryButton"}
            onClick={clickMyInventoryButton}
            bgColor="#4f9e30"
            textColor="#000000"
          />
          <Button
            type="button"
            label="Back"
            size="lg"
            testId={"installBackButton"}
            onClick={handleBackbutton}
            bgColor="#4f9e30"
            textColor="#000000"
          />
        </AssetButtons>
      </>
    );
  };

  const renderCollectAssetFields = () => {
    return (
      <>
        <div style={{ marginTop: "25px" }}>
          <AssetStatus> {asset?.asset_status || "Unknown"}</AssetStatus>
        </div>
        <ButtonSection>
          <AssetButtons>
            <Button
              type="button"
              label="Save"
              size="lg"
              testId={"CollectSaveButton"}
              onClick={() => changeAssetStatus("collect_asset")}
              bgColor="#4f9e30"
              textColor="#000000"
            />
            <Button
              type="button"
              label="Back"
              size="lg"
              testId={"collectBackButton"}
              onClick={handleBackbutton}
              bgColor="#4f9e30"
              textColor="#000000"
            />
          </AssetButtons>
        </ButtonSection>
      </>
    );
  };

  const renderReturnAssetFields = () => {
    return (
      <>
        {/* <AssetStatus>
          <CheckBoxdata>
            <input type="checkbox" onChange={handleCheckboxData}/>
          </CheckBoxdata>

          <StatusContent>
            <div>This device is not in your Inventory.</div>
            <div> Do you want to add it</div>
          </StatusContent>
        </AssetStatus> */}
        <AgingMessage>
          <div
            style={{
              position: "relative",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            <ReasonCodeImage src={ReasonCodeIcon} />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <span style={{ color: "Red", fontSize: "30px" }}>*</span>
              {"ReasonCode"}
            </div>
          </div>
          <ReasonCodeList>
            <ReasonSelectList
              value={reasonCodeValue}
              onChange={handleReasonCode}
              disabled={asset.reasonid === "R003"}
            >
              <option value="0">Select Reason Code</option>
              {apiReasonCodes &&
                Array.isArray(apiReasonCodes) &&
                apiReasonCodes.map((item: any, index: number) => {
                  return (
                    <option key={`${item.reason}-${index}`} value={item.reason}>
                      {item.description}
                    </option>
                  );
                })}
            </ReasonSelectList>
          </ReasonCodeList>
          {/* <ReasonDropIcon src={DropDownIcon} /> */}
        </AgingMessage>
        <AssetButtons>
          <Button
            type="button"
            label="Save"
            size="lg"
            testId={"ReturnSaveButton"}
            onClick={() => changeAssetStatus("return_asset")}
            bgColor="#4f9e30"
            textColor="#000000"
            disabled={!(reasonCodeValue && reasonCodeValue.length > 1)}
          />
          <Button
            type="button"
            label="Back"
            size="lg"
            testId={"returnBackButton"}
            onClick={handleBackbutton}
            bgColor="#4f9e30"
            textColor="#000000"
          />
        </AssetButtons>
      </>
    );
  };

  const renderAssetFields = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "80%",
            margin: "auto",
          }}
        >
          <div style={{ width: "100%" }}>
            <textarea
              rows={2}
              value={assetnumber}
              onChange={handleAssetNumber}
              style={{
                resize: "none",
                overflow: "hidden",
                width: "100%",
                padding: "50px 10px",
                cursor: "pointer",
                fontSize: "25px",
                textAlign: "center",
              }}
              placeholder={"Enter Asset Number.."}
              data-testid={"assetField"}
            />
            <CameraScanner
              handleCamWindow={setCameraWindow}
              handleScan={setAssetNumber}
              handleCamToggle={setLoading}
            />
          </div>
          &nbsp;
          <Button
            type="button"
            disabled={!(assetnumber && assetnumber.length > 7)}
            label={"Search"}
            size="lg"
            testId={"SearchButton"}
            onClick={verifyAsset}
            bgColor="#0e7d11"
          />
        </div>
        <div style={{ marginTop: "30px" }}>
          {isWarning ? (
            <>
              <Button
                type="button"
                disabled={!(assetnumber && assetnumber.length > 0 && assetValidation)}
                label="Continue"
                size="lg"
                testId={"ContinueBtn"}
                onClick={onContinueButton}
                bgColor="#0e7d11"
              />
              &nbsp;
              <Button
                type="button"
                disabled={!(assetnumber && assetnumber.length > 0)}
                label="Reset"
                size="lg"
                testId={"ResetBtn"}
                onClick={onResetBtn}
                bgColor="#0e7d11"
              />
            </>
          ) : (
            <Button
              type="button"
              label="Home"
              size="lg"
              testId={"HomeButton"}
              onClick={onPressHome}
              bgColor="#0e7d11"
            />
          )}

          {/* &nbsp; */}
        </div>
      </>
    );
  };

  const renderTabs = () => {
    return (
      <div
        style={{
          height: "100%",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
        data-testid="TabContent"
      >
        <AssetScandiv>
          <div>Asset Scanned</div>
          <div>{assetnumber}</div>
        </AssetScandiv>
        <div
          style={{
            border: "1px solid #b3c5d6",
            marginTop: "10px",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <div style={{ display: "flex", flex: "1 1 100%" }}>
              <Tab
                isActive={installAsset === ScanAssetType.COLLECTASSET}
                type="button"
                disabled={collectDisabledTabs}
                onClick={() =>
                  onInstallAssetCategory(ScanAssetType.COLLECTASSET)
                }
                data-testid="collecttab"
              >
                <img src={CollectAssetSmallIcon} height="30px" /> Collect Asset
              </Tab>
              <Tab
                type="button"
                disabled={scanOutDisabledTabs}
                isActive={installAsset === ScanAssetType.INSTALL}
                onClick={() => onInstallAssetCategory(ScanAssetType.INSTALL)}
                data-testid="scanouttab"
              >
                <img src={InstallAssetSmallIcon} height="30px" /> Scan Out
              </Tab>
              <Tab
                type="button"
                disabled={returnDisabledTabs}
                isActive={installAsset === ScanAssetType.RETURN}
                onClick={() => onInstallAssetCategory(ScanAssetType.RETURN)}
                data-testid="returntab"
              >
                <img src={ReturnAssetSmallIcon} height="30px" /> Return
              </Tab>
            </div>
          </div>
          {installAsset === ScanAssetType.INSTALL && renderInstallAssetFields()}
          {installAsset === ScanAssetType.COLLECTASSET &&
            renderCollectAssetFields()}
          {installAsset === ScanAssetType.RETURN && renderReturnAssetFields()}
        </div>
      </div>
    );
  };

  return (
    <>
      <PopupModal
        show={show}
        handleClose={hideModal}
        primaryAction={handleHomePage}
        yestestId={"homeYesButton"}
        notestId={"homeNoButton"}
      ></PopupModal>
      <PopupModal
        show={showinventory}
        handleClose={hideinventory}
        primaryAction={hadleMyInventory}
        yestestId={"inventoryYesButton"}
        notestId={"inventoryNoButton"}
      ></PopupModal>

      <PopupModal
        show={showBackButton}
        handleClose={hideBackButton}
        primaryAction={handlePopupBackbutton}
        yestestId={"backYesButton"}
        notestId={"backNoButton"}
      ></PopupModal>

      {!cameraWindow && (
        <BreadCrumb
          inputRoutes={breadCrumRoutes}
          callBackDataCheck={handleHomeButton}
        />
      )}
      <Container wide>
        <Card>
          {cameraWindow && <>{!isLoading && <CameraWindow />}</>}
          {!cameraWindow && (
            <ScanScreen
              imageName="scanasseticon"
              scanTitle={"Scan Individual Asset Number - "}
              scanDescription={
                "To add Serialised Assets into your Vehicle stock Inventory"
              }
              isSuccesAlertShown={isSuccessAsset}
              instructions={instructions}
              message={errorMessage}
              isWarningMessage={isWarning}
              subInstructions={subInstructions}
            >
              <>
                {isLoading && (
                  <div style={{ position: "absolute", left: 0, right: 0 }}>
                    <LoadingIndicators
                      size="lg"
                      position="center"
                      colour="gray"
                      type="circle"
                    />
                  </div>
                )}

                {!isAssetFound && renderAssetFields()}
                {isAssetFound && renderTabs()}
              </>
            </ScanScreen>
          )}
        </Card>
      </Container>
    </>
  );
};

export default ScanAsset;
