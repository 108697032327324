import styled from "styled-components";
const Model = styled.div`
background: antiquewhite;
height: 150px;
margin: 100px 10px 10px;
border-radius: 10px;
border: 2px solid #ff4500;
color: rgba(0, 0, 139, 0.7);
font-size: 25px;
display: flex;
justify-content: center;
align-items: center;
`;

export {Model};