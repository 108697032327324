import styled from "styled-components";

const InventoryTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const InventoryThead = styled.thead`
  background-color: #b9d7f7;
  color: #0061bb;
`;

const InventoryTh = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
  text-align: center;

  @media (max-width: 450px) {
    padding: 5px;
    font-size: 13px;
    border-bottom: 1px solid red;
  }
`;

const InventoryTd = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
  text-align: center;

  @media (max-width: 450px) {
    padding: 5px;
    font-size: 12px;
  }
`;
const InventoryTr = styled.tr`
  &:nth-child(even) {
    background-color: #83d177;
  }
  &:nth-child(odd) {
    background-color: #a7e19d;
  }
`;

const InventoryTrhead = styled.tr``;

const InputTextdiv = styled.div`
margin-right:20px;
width: 35%;

input {
   font-size: 18px;
   border-radius: 3px;
 }

@media (max-width: 450px) {
   width: 40%;
   height: 30px;
   input {
     font-size: 13px;
   }
`;

const InventoryTbody = styled.tbody``;

const OfflineMsgSpan = styled.span`
  color: red;
  color: rgba(0, 0, 139, 0.7);
  font-size: 30px;
  text-align: center;
  padding: 50px;
  border: 2px solid #ff4500;
  border-radius: 10px;
  position: fixed;
  background: antiquewhite;
  width: 500px;
  height: 150px;
  top: 45%;
  left: 35%;
`;
export {
  InventoryTable,
  InventoryTh,
  InventoryTd,
  InventoryTr,
  InventoryThead,
  InputTextdiv,
  InventoryTrhead,
  InventoryTbody,
  OfflineMsgSpan,
};
