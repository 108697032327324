import styled from 'styled-components';

const StackContainer = styled.div`
	width: 100%;
	background-color: #0061bb;
    text-align: center;
    height: 60px;
    display: flex;
    flex-direction: row;

    @media (max-width: 450px) {
        height: 35px;
      }
`;

const StackLogo = styled.img`
    margin-right: 15px;
    float: left;
    height: 60px;

    @media (max-width: 450px) {
        height: 35px;
      }
`;

const StackTitle = styled.div`
	display: flex;
	justify-content: center;
    align-items: center;
    height: 60px;
    color: #ffffff;
    font-size: 30px;
    flex: 1;

    @media (max-width: 450px) {
        font-size: 15px;
        height: 35px;
      }
`;
const NetworkState = styled.div`
    height: 60px;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right: 15px;

    @media (max-width: 450px) {
        height: 35px;

        img{
            height: 15px;
        }
      }
`;

export {
    StackContainer,
    StackLogo,
    StackTitle,
    NetworkState
};
