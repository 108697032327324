import styled from "styled-components";
import { TabProps } from "./types";

const Tab = styled.button<TabProps>`
  height: 60px;
  background-color: #5b9bd5;
  border: 1px solid #b3c5d6;
  flex-basis: 33.333%;
  padding: 7px 0px;
  cursor: pointer;
  color: #ffffff;
  font-size:15px;
  font-weight:bold;
  ${({ isActive }) =>
    isActive &&
    `
      background-color: #ffffff;
      color: #000000;
    `}

    &:disabled {
      // background: linear-gradient(
      //   0deg,
      //   rgba(19, 124, 189, 0.5),
      //   rgba(19, 124, 189, 0.5)
      // );
      background : #5b9bd5;
      cursor: not-allowed;
      pointer-events: all !important;
    }
    display:flex;
  align-items: center;
`;

const AssetStatus = styled.div`
  display: flex;
  background-color: #dfe2e7;
  justify-content: center;
  font-size: 18px;
  border: 2px solid #dfe2e7;
  padding: 8px;
  margin: 5px 5px 0px 5px;
  align-items: center;
  font-weight:bold;
  height: 60px;
`;

const AgingMessage = styled.div`
  display: flex;
  background-color: #ffffff;
  font-size: 18px;
  border: 1px solid #dfe2e7;
  margin: 10px 5px 0px 5px;
  height: 60px;
  font-weight:bold;
`;
const AgingImage = styled.img`
  height: 60px;
`;

const AgingMsgContent = styled.div`
  flex: 1;
  align-self: center;
`;
const AssetButtons = styled.div`
  display: flex;
  margin: 7px 0px 11px 0px;
  padding: 5px;
  justify-content: space-between;
  height:60px;
`;

const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  justify-content: flex-end;
`;

const CheckBoxdata = styled.div`
  transform: scale(1.5);
`;

const StatusContent = styled.div`
  font-size: 15px;
  flex: 1;
`;
const AssetScandiv = styled.div`
padding: 10px;
background-color: #5b9bd5;
justify-content: center;
font-size: 22px;
border-radius: 10px;
height:65px;
color : #ffffff;
`;
const ReasonCodeImage = styled.img`
  height: 60px;
  width:150px;
`;
const ReasonDropIcon = styled.img`
  padding: 7px 0px;
  // width: 50px;
`;
const ReasonCodeList = styled.div`
  flex: 1;
  align-self: center;
`;
const ReasonSelectList = styled.select`
  border: 1px solid #dfe2e7;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-size: inherit;
  font-weight: bold;
  height:60px;
  text-align:center;
  &:disabled {
    background : #5b9bd5;
    cursor: not-allowed;
    pointer-events: all !important;
    color:#ffffff;
  }
  
`;

export {
  Tab,
  AssetStatus,
  AgingMessage,
  AgingImage,
  AgingMsgContent,
  AssetButtons,
  ButtonSection,
  CheckBoxdata,
  StatusContent,
  AssetScandiv,
  ReasonCodeImage,
  ReasonDropIcon,
  ReasonCodeList,
  ReasonSelectList,
};
