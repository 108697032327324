import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: #fff;
  margin: 0;
  position: fixed;
  padding: 10px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  border-top: 1px solid #ff4500;
  display: flex;
  align-items: center;

  @media (max-width: 450px) {
    height: 35px;
  }
  
`;

const FooterRightsData = styled.div`
font-size: 15px;
font-weight:bold;

@media (max-width: 450px) {
  font-size: 8px;
  margin-left: 0;
}
`;

const FooterPrivacyData = styled.div`
font-size: 15px;
font-weight:bold;
margin-left: auto;

@media (max-width: 450px) {
  font-size: 10px;
  margin: auto;
}

`;

export { FooterContainer, FooterPrivacyData, FooterRightsData };